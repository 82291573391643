@use "../abstracts/base" as *;

html {
  @include font-vw(16, 1280);

  @include responsive(in-md) {
    @include font-vw(16, 375);
  }

  @include responsive(in-xs) {
    font-size: 16px;
  }

  @include responsive(over-lg) {
    font-size: 18px;
  }
}

body {
  box-sizing: border-box;
  font-family: $font-noto-sans;
  font-weight: $regular; /* stylelint-disable-line */
  text-align: justify;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

a {
  color: $white;
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5rem;
  padding-left: 5rem;

  @include responsive(in-md) {
    padding: 0 5%;
  }

  &--full-width {
    max-width: unset;
    padding: 0;

    @include responsive(in-md) {
      padding: 0;
    }
  }
}

.no-pc {
  display: none;

  @include responsive(in-md) {
    display: block;
  }
}

.no-sp {
  @include responsive(in-md) {
    display: none;
  }
}
