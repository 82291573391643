@use "../abstracts/base" as *;

.works {
  .construction {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    &__item {
      display: flex;
      gap: 2.5rem;
      margin-top: 3.5rem;

      @include responsive(in-md) {
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &__slider {
      width: 48%;

      @include responsive(in-md) {
        width: 100%;
      }

      .splide {
        &__arrow {
          width: auto;
          height: 1.383rem;
          background-color: transparent;

          @include responsive(in-md) {
            height: 1.038rem;
          }

          svg {
            width: auto;
            height: 100%;
            fill: $red;
          }
        }

        &__pagination {
          bottom: 0;
          margin-bottom: 1rem;
          gap: 1rem;

          @include responsive(in-md) {
            margin-bottom: 0.475rem;
            gap: 0.5rem;
          }

          &__page {
            width: 0.75rem;
            height: 0.75rem;
            margin: unset;
            background-color: $white;

            @include responsive(in-md) {
              width: 0.5rem;
              height: 0.5rem;
            }

            &.is-active {
              transform: unset;
              background-color: $red;
            }
          }
        }
      }
    }

    h3 {
      padding-left: 1rem;
      border-left: solid 0.375rem $red;
      font-size: 2rem;
      line-height: 1.2;

      @include responsive(in-md) {
        padding-left: 0.625rem;
        border-left: solid 0.125rem $red;
        font-size: 1.125rem;
      }
    }

    &__detail {
      flex: 1;

      table {
        width: 100%;
        margin-top: 2rem;

        @include responsive(in-md) {
          margin-top: unset;
        }

        td {
          width: 70%;
          padding: 1rem;
          border-collapse: collapse;
          border: 1px solid $line-gray;

          @include responsive(in-md) {
            padding: 0.75rem;
            font-size: 0.75rem;
          }

          &:first-of-type {
            width: 30%;

            @include responsive(in-md) {
              width: 40%;
            }
          }
        }
      }
    }
  }

  .history {
    margin-top: 5rem;
    padding-bottom: 5rem;

    @include responsive(in-md) {
      margin-top: 3.539rem;
      padding-bottom: 3.5rem;
    }

    h2 {
      position: relative;
      width: 100%;
      padding: 0.875rem 1.5rem;
      border: solid 1px $red;
      color: $red;
      font-size: 1.25rem;

      @include responsive(in-md) {
        padding: 0.938rem 1.5rem;
        font-size: 1.125rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1rem;
        height: 1px;
        background-color: $red;
      }
    }

    &__scroll-layer {
      margin-top: 1.5rem;

      @include responsive(in-md) {
        position: relative;
        margin-top: 1rem;
        overflow: hidden;
        overflow-x: scroll;
      }

      .scroll-hint {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7.5rem;
        transform: translate(-50%, -50%);
        box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.2);
        pointer-events: none;

        &.hide {
          transition: 300ms;
          opacity: 0;
        }
      }
    }

    &__table {
      width: 100%;

      @include responsive(in-md) {
        width: 195.3%;
      }

      td {
        padding: 0.75rem;

        @include responsive(in-md) {
          padding: 0.5rem;
          font-size: 0.75rem;
        }

        &:nth-of-type(1) {
          width: 50%;

          @include responsive(in-md) {
            width: 47%;
          }
        }

        &:nth-of-type(2) {
          width: 12.5%;

          @include responsive(in-md) {
            width: 22.7%;
          }
        }

        &:nth-of-type(3) {
          width: 37.5%;

          @include responsive(in-md) {
            width: 30.3%;
          }
        }
      }

      .title {
        border: solid 1px $red;
        background-color: $red;
        color: $white;

        td + td {
          border-left: solid 1px $white;
        }
      }

      .year {
        border: solid 1px $line-gray;
        border-top: unset;
        background-color: $dark-gray;
        color: $white;
      }

      .detail {
        td {
          border: solid 1px $line-gray;
          border-top: unset;
        }
      }
    }
  }
}
