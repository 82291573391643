@use "../abstracts/base" as *;

.service-solutions {
  // start: お悩みございませんか？
  .worries {
    max-width: unset;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $light-red;

    @include responsive(in-md) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }

    .content {
      max-width: 1260px;
      margin: 0 auto;
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: $bold;
      line-height: 1.2;

      @include responsive(in-md) {
        display: block;
        font-size: 1.5rem;
        text-align: center;
      }

      span.strong {
        display: inline-block;
        padding: 0 0.25rem;
        color: $red;
        font-size: 3rem;

        @include responsive(in-md) {
          font-size: 2rem;
        }
      }

      span.sp-inline-flex {
        display: inline-flex;
        align-items: center;
      }
    }

    &__main-content {
      margin-top: 2.5rem;

      @include responsive(in-md) {
        margin-top: 1.5rem;
      }

      .image {
        position: relative;
        z-index: 2;
        width: 56%;
        margin: 0 auto;

        @include responsive(in-md) {
          width: 80%;
        }
      }
    }

    .worries-list {
      position: relative;
      z-index: 1;
      margin-top: -3.5rem;
      padding: 6.25rem 2.5rem 5rem;
      border: solid 5px $red;
      border-radius: 1rem;
      background-color: $white;

      @include responsive(in-md) {
        margin-top: -2.5rem;
        padding: 3.5rem 1.5rem 2.5rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 2.5rem 0;

        @include responsive(in-md) {
          gap: 1rem 0;
        }

        & > li {
          display: flex;
          align-items: flex-start;
          gap: 0 1rem;

          @include responsive(in-md) {
            gap: 0 0.5rem;
          }

          span {
            display: inline-block;
            flex-shrink: 0;
            width: 2.25rem;

            @include responsive(in-md) {
              width: 1.25rem;
              padding-top: 0.25rem;
            }
          }
        }
      }

      p {
        font-size: 2rem;
        font-weight: $bold;

        @include responsive(in-md) {
          font-size: 1.125rem;
          font-feature-settings: "palt";
          line-height: 1.4;
        }
      }

      .and-more {
        margin-top: 2.5rem;
        text-align: center;

        @include responsive(in-md) {
          margin-top: 1rem;
        }
      }
    }
  }
  // end: お悩みございませんか？

  // start: 測量・設計・登記のプロがそのお悩み解決します！
  .our-solutions {
    max-width: unset !important;
    padding-top: 5rem;
    padding-right: unset !important;
    padding-left: unset !important;

    @include responsive(in-md) {
      padding-top: unset;
    }

    &__heading {
      position: relative;
      width: fit-content;
      margin: 0 auto;

      h2 {
        font-size: 2rem;
        font-weight: $bold;
        text-align: center;

        @include responsive(in-md) {
          margin-top: 1rem;
          font-size: 1.125rem;
        }
      }

      span.strong {
        display: inline-block;
        padding-right: 0.25rem;
        color: $red;
        font-size: 3rem;

        @include responsive(in-md) {
          padding-right: unset;
          font-size: 1.75rem;
        }
      }

      span.line-2 {
        display: inline-block;
        margin-top: 0.5rem;
      }

      .icon {
        position: absolute;
        top: 50%;
        width: 7.5rem;

        @include responsive(in-md) {
          position: static;
          width: 5rem;
        }

        &--men {
          left: -1.5rem;
          transform: translate(-100%, -50%);

          @include responsive(in-md) {
            display: none;
          }
        }

        &--women {
          right: -1.5rem;
          transform: translate(100%, -50%);

          @include responsive(in-md) {
            right: unset;
            margin: -2.5rem auto 0;
            transform: unset;
          }
        }
      }
    }

    &__message {
      width: 45rem;
      margin: 2.5rem auto 0;
      font-size: 1.125rem;
      line-height: 1.8;

      @include responsive(in-md) {
        width: 100%;
        margin: 1rem auto 0;
        padding: 0 5%;
        font-size: 1rem;
      }
    }

    &__slider {
      width: 100%;
      margin-top: 2.5rem;
    }
  }
  // end: 測量・設計・登記のプロがそのお悩み解決します！

  // start: サービス一覧
  .services {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include responsive(in-md) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }

    h2 {
      color: $red;
      font-size: 3rem;
      font-weight: $bold;

      @include responsive(in-md) {
        font-size: 2rem;
        text-align: center;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 2.5rem 0;
      margin-top: 2.5rem;

      @include responsive(in-md) {
        gap: 1.5rem 0;
        margin-top: 2rem;
      }

      li {
        display: flex;
        align-items: center;
        gap: 0 2.5rem;

        @include responsive(in-md) {
          flex-direction: column;
          align-items: unset;
          gap: 1rem 0;
        }

        .image {
          flex-shrink: 0;
          width: 20rem;
          overflow: hidden;
          border-radius: 0.5rem;

          @include responsive(in-md) {
            width: 100%;
          }
        }

        .details {
          width: 100%;

          @include responsive(in-md) {
            display: flex;
            flex-direction: column;
          }

          h3 {
            font-size: 2rem;
            font-weight: $bold;

            @include responsive(in-md) {
              font-size: 1.5rem;
            }
          }

          p.explain {
            margin-top: 1rem;
            font-size: 1.125rem;
            line-height: 1.4;

            @include responsive(in-md) {
              margin-top: 0.5rem;
              font-size: 1rem;
            }
          }

          a {
            display: inline-flex;
            align-items: center;
            gap: 0 0.5rem;
            margin-top: 1rem;

            @include responsive(in-md) {
              align-self: flex-end;
              margin-left: auto;
            }

            &:hover {
              p::after {
                animation-name: link-hover;
              }
            }

            p {
              position: relative;
              color: $red;
              font-size: 1.125rem;

              @include responsive(in-md) {
                font-size: 1rem;
              }

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                animation-duration: 300ms;
                animation-timing-function: ease-in-out;
                background-color: $red;
              }
            }

            .arrow {
              width: 2rem;
              padding: 0.5rem;
              border: solid 1px $red;
              border-radius: 100vh;
            }
          }
        }
      }
    }
  }
  // end: サービス一覧

  // start: ご相談から解決までの流れ
  .flow {
    max-width: unset;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $light-green;

    @include responsive(in-md) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }

    .content {
      width: 45rem;
      margin: 0 auto;

      @include responsive(in-md) {
        width: 100%;
      }
    }

    h2 {
      font-size: 2.5rem;
      font-weight: $bold;
      text-align: center;

      @include responsive(in-md) {
        font-size: 2rem;
        line-height: 1.2;
      }

      // よくあるご質問の見出し
      &.questions-heading {
        margin-top: 3.5rem;

        @include responsive(in-md) {
          margin-top: 2.5rem;
        }
      }

      span {
        color: $green;
      }
    }

    .heading-sub {
      margin-top: 0.75rem;
      font-size: 1.25rem;
      text-align: center;

      @include responsive(in-md) {
        margin-top: 0.5rem;
        font-size: 1rem;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;
      margin-top: 2.5rem;

      @include responsive(in-md) {
        margin-top: 1.5rem;
      }

      li.card {
        padding: 1.5rem;
        border-radius: 0.5rem;
        background-color: $white;

        h3 {
          display: flex;
          align-items: center;
          gap: 0 0.5rem;
          color: $green;
          font-size: 1.25rem;
          font-weight: $bold;

          @include responsive(in-md) {
            font-size: 1.125rem;
            line-height: 1.2;
          }

          span {
            font-family: $font-robot;
            font-size: 2rem;
          }
        }

        p.explain {
          margin-top: 0.75rem;
          line-height: 1.4;

          @include responsive(in-md) {
            margin-top: 0.25rem;
          }
        }

        .tel {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: solid 1px $green;

          @include responsive(in-md) {
            flex-direction: column;
            align-items: unset;
          }

          .tel-heading {
            font-weight: $bold;
          }

          a {
            display: inline-block;
            margin-left: 0.5rem;
            color: $black;
            font-family: $font-robot;
            font-size: 2.5rem;
            pointer-events: none;

            @include responsive(in-md) {
              margin-top: 0.5rem;
              margin-left: unset;
              font-size: 2rem;
              pointer-events: all;
            }
          }

          .business-hour {
            font-size: 0.75rem;

            @include responsive(in-md) {
              margin-top: 0.25rem;
            }
          }
        }
      }

      li.arrow {
        width: 2rem;
        margin: 0 auto;
      }
    }

    &__questions {
      display: flex;
      align-items: stretch;
      gap: 0 1rem;
      margin-top: 2rem;

      @include responsive(in-md) {
        flex-direction: column;
        gap: 1.5rem 0;
        margin-top: 1.5rem;
      }

      li {
        display: flex;
        position: relative;
        z-index: 1;
        flex-direction: column;
        width: calc(50% - 0.5rem);
        padding-top: 1.625rem;

        @include responsive(in-md) {
          width: 100%;
        }

        .question {
          padding-top: 1.25rem;
          padding-bottom: 0.875rem;
          background-color: $green;
          text-align: center;

          span {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            width: 4.875rem;
            height: 4.875rem;
            padding-top: 0.375rem;
            transform: translateX(-50%);
            border-radius: 100vh;
            background-color: $green;
            color: $white;
            font-family: $font-robot;
            font-size: 1.75rem;
            font-weight: $bold;
          }

          h3 {
            position: relative;
            z-index: 2;
            color: $white;
            font-size: 1.125rem;
            font-weight: $bold;
          }
        }
      }

      .answer {
        width: 100%;
        height: 100%;
        padding: 1rem;
        border: solid 1px $green;
        background-color: $white;

        .answer-main {
          display: flex;
          gap: 0 0.5rem;

          @include responsive(in-md) {
            font-size: 1.125rem;
          }

          span {
            color: $green;
            font-family: $font-robot;
            font-size: 1.5rem;
            font-weight: $bold;
          }

          p {
            color: $green;
            font-weight: $bold;
            line-height: 1.4;
          }
        }

        .message {
          margin-top: 0.5rem;
          line-height: 1.4;
        }
      }
    }
  }
  // end: ご相談から解決までの流れ

  // start: お問い合わせ
  .contact {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include responsive(in-md) {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
    }

    .content {
      max-width: 1260px;
      margin: 0 auto;
      padding: 2.5rem;
      border: solid 3px $green;

      @include responsive(in-md) {
        padding: 1.5rem 0 2rem;
      }
    }

    h2 {
      color: $green;
      font-size: 1.5rem;
      font-weight: $bold;
      text-align: center;

      @include responsive(in-md) {
        padding: 1rem 0;
        background-color: $green;
        color: $white;
        font-size: 1.25rem;
        line-height: 1.4;
      }
    }

    &__tel-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      @include responsive(in-md) {
        flex-direction: column;
      }

      .tel-heading {
        margin-right: 2rem;
        padding: 1rem 2rem 1rem 0;
        border-right: solid 4px $green;
        color: $green;
        font-size: 1.5rem;
        font-weight: $bold;

        @include responsive(in-md) {
          padding: unset;
          border-right: unset;
          font-size: 1.125rem;

          &::after {
            content: "";
            display: block;
            width: 3rem;
            height: 4px;
            margin: 0.5rem auto 0;
            background-color: $green;
          }
        }
      }

      .tel-number {
        display: flex;
        align-items: center;
        gap: 0 0.5rem;

        @include responsive(in-md) {
          gap: 0 0.25rem;
          margin-top: 1rem;
        }

        .icon {
          display: block;
          width: 2.5rem;

          @include responsive(in-md) {
            width: 1.75rem;
          }
        }

        a {
          display: inline-block;
          color: $green;
          font-family: $font-robot;
          font-size: 3.5rem;
          font-weight: $bold;
          pointer-events: none;

          @include responsive(in-md) {
            font-size: 2.5rem;
            pointer-events: all;
          }
        }
      }

      .business-hour {
        color: $green;
        font-size: 1.125rem;
        font-weight: $bold;

        @include responsive(in-md) {
          margin-top: 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
  // end: お問い合わせ
}
