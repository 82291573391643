@use "../abstracts/base" as *;

.privacy-policy {
  .privacy {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include responsive(in-md) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }

    &__detail {
      margin-top: 3.5rem;

      @include responsive(in-md) {
        margin-top: 2rem;
      }
    }

    h2 {
      margin-top: 2.5rem;
      color: $red;
      font-size: 1.125rem;
      font-weight: $bold;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        font-size: 1rem;
      }

      &:first-of-type {
        margin-top: 0;
      }

      & + p {
        margin-top: 1rem;
        font-size: 1.125rem;
        line-height: 1.4;

        @include responsive(in-md) {
          margin-top: 0.5rem;
          font-size: 0.875rem;
        }
      }
    }

    &__link {
      display: flex;
      align-items: end;
      width: fit-content;
      transition: all 300ms;
      outline: none;
      color: $black;
      text-decoration: underline;
      gap: 0.25rem;
      text-decoration-skip-ink: none;
      text-underline-offset: 0.08em;

      @include responsive(in-md) {
        font-size: 0.875rem;
      }

      &:first-of-type {
        margin-top: 1rem;

        @include responsive(in-md) {
          margin-top: 0.5rem;
        }
      }

      &:last-of-type {
        margin-top: 0.688rem;

        @include responsive(in-md) {
          margin-top: 0.5rem;
        }
      }

      &:hover {
        opacity: 0.6;
      }

      .icon {
        width: 0.625rem;
        height: auto;
      }
    }

    &__date {
      margin-top: 2.5rem;
      font-size: 1.125rem;
      line-height: 1.4;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        font-size: 0.875rem;
      }
    }

    &__tel {
      color: $black;
      pointer-events: none;
    }
  }
}
