@use "../abstracts/base" as *;

.recruit {
  padding-bottom: 5rem;

  @include responsive(in-md) {
    padding-bottom: 3.5rem;
  }

  .recruit-message {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    .content {
      display: flex;
      position: relative;
      gap: 0 2.75rem;

      @include responsive(in-md) {
        flex-direction: column-reverse;
      }
    }

    &__slider {
      width: 100%;
      height: auto;

      @include responsive(in-md) {
        margin-top: 3rem;
      }

      &__image {
        overflow: hidden;
        border-radius: 0.5rem;
      }
    }

    &__main-content {
      flex-shrink: 0;
      writing-mode: vertical-rl;

      @include responsive(in-md) {
        writing-mode: unset;
      }

      h2 {
        margin-left: 4rem;
        font-size: 2rem;
        letter-spacing: 0.2em;

        @include responsive(in-md) {
          margin-left: unset;
          font-size: 1.5rem;
        }

        span.strong {
          font-size: 3rem;

          @include responsive(in-md) {
            display: inline-block;
            margin-right: -0.5em;
            margin-left: -0.5em;
            font-size: 2.5rem;
          }
        }

        span.red {
          color: $red;
        }

        span.shift-position {
          display: inline-block;
          margin-right: 1rem;
          transform: translateY(-0.8em);

          @include responsive(in-md) {
            margin-top: 0.5rem;
            margin-right: unset;
            transform: unset;
          }
        }

        span.shift-position-sub {
          display: inline-block;
          transform: translateY(-0.4em);

          @include responsive(in-md) {
            transform: unset;
          }
        }
      }

      .message-text-wrapper {
        font-size: 1.125rem;
        letter-spacing: 0.2em;

        @include responsive(in-md) {
          margin-top: 2rem;
          font-size: 1rem;
          letter-spacing: 0;
        }

        &:not(:first-of-type) {
          margin-right: 2.5rem;

          @include responsive(in-md) {
            margin-top: 2rem;
            margin-right: unset;
          }
        }

        p:not(:first-of-type) {
          margin-right: 0.75rem;

          @include responsive(in-md) {
            margin-top: 1rem;
            margin-right: unset;
          }
        }
      }
    }

    .decoration-text {
      position: absolute;
      bottom: -2.5rem;
      left: 26%;
      width: 46%;

      @include responsive(in-md) {
        bottom: 36%;
        left: 50%;
        width: 100%;
        transform: translate(-50% 0);
      }
    }
  }

  .our-works {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    h2 {
      color: $red;
      font-size: 3rem;

      @include responsive(in-md) {
        font-size: 1.5rem;
      }
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      gap: 3.5rem 0;
      margin-top: 2.5rem;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        gap: 2rem 0;
      }

      li {
        counter-increment: list-number 1;
      }

      .heading {
        position: relative;
        width: 100%;
        height: auto;

        .image {
          overflow: hidden;
          border-radius: 0.5rem;

          &::before {
            @include responsive(in-md) {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 0.5rem;
              background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.4) 100%);
            }
          }
        }

        h3 {
          position: absolute;
          bottom: 1.5rem;
          left: 0.5rem;
          color: $white;
          font-family: $font-shippori-mincho;
          font-size: 3rem;
          text-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25);

          @include responsive(in-md) {
            bottom: 1rem;
            font-size: 2rem;
            text-shadow: unset;
          }

          &::before {
            content: "0" counter(list-number);
            display: inline-block;
            margin-right: 0.5rem;
          }
        }
      }

      .text-wrapper {
        margin-top: 2.5rem;

        @include responsive(in-md) {
          margin-top: 1rem;
        }

        h3 {
          font-size: 2rem;
          text-align: center;

          @include responsive(in-md) {
            font-size: 1.25rem;
          }
        }

        p {
          max-width: 45rem;
          margin: 1rem auto 0;
          font-size: 1.125rem;
          line-height: 1.8;

          @include responsive(in-md) {
            margin-top: 0.5rem;
            font-size: 0.875rem;
            line-height: 1.6;
          }
        }
      }
    }
  }

  .interview {
    max-width: 1440px;
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    h2 {
      padding: 0 5rem;
      color: $red;
      font-size: 3rem;

      @include responsive(in-md) {
        padding: 0 5%;
        font-size: 1.5rem;
      }
    }

    &__explain-text {
      margin-top: 2.5rem;
      padding: 0 5rem;
      font-size: 1.125rem;

      @include responsive(in-md) {
        margin-top: 1rem;
        padding: 0 5%;
        font-size: 0.875rem;
      }
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      gap: 3.5rem 0;
      margin-top: 3.5rem;

      @include responsive(in-md) {
        margin-top: 2rem;
        gap: 2.5rem 0;
      }

      & > li.active {
        .interview-wrapper {
          padding: 2.5rem 5rem 3.5rem;

          @include responsive(in-md) {
            padding: 2rem 5% 3.5rem;
          }
        }
      }

      .main-heading {
        position: relative;

        @include responsive(in-md) {
          overflow: hidden;
        }
      }

      .description {
        display: flex;
        position: absolute;
        top: 0;
        left: 5rem;
        align-items: center;
        width: fit-content;
        height: 100%;
        padding: 1.5rem;
        background-color: $red;

        @include responsive(in-md) {
          position: static;
          width: 100%;
          height: auto;
          padding: 1.25rem 5%;
        }

        .decoration {
          position: absolute;
          right: 0;
          bottom: 25%;
          width: fit-content;
          height: 3.5rem;
          transform: translateX(2.5rem);

          @include responsive(in-md) {
            bottom: 3rem;
            transform: translateX(1.5rem);
          }

          img {
            width: auto;
            height: 100%;
          }
        }

        .text-wrapper {
          display: flex;
          gap: 0 0.75rem;
          align-items: center;
          color: $white;

          .number {
            font-family: $font-shippori-mincho;
            font-size: 4.5rem;

            @include responsive(in-md) {
              font-size: 2.5rem;
            }
          }

          .details {
            // PCの時に数字とテキストの位置が微妙にズレて気になるので微調整のために追加
            transform: translateY(10%);

            @include responsive(in-md) {
              transform: unset;
            }
          }

          .unit {
            font-size: 1.125rem;

            @include responsive(in-md) {
              font-size: 0.875rem;
            }
          }

          .join {
            margin-top: 0.25rem;
            font-size: 1.5rem;

            @include responsive(in-md) {
              font-size: 1.25rem;
            }
          }
        }
      }

      .interview-wrapper {
        padding: 1.5rem 5rem;
        transition: all 600ms;
        border-right: solid 1px $red;
        border-bottom: solid 1px $red;
        border-left: solid 1px $red;

        @include responsive(in-md) {
          padding: 1.5rem 5%;
        }

        .interview-content {
          display: flex;
          align-items: flex-start;
          gap: 0 2.5rem;
          height: 0;
          overflow: hidden;
          transition: all 600ms;

          @include responsive(in-md) {
            flex-direction: column-reverse;
          }

          &.active {
            overflow: visible;

            .image {
              position: sticky;
              top: 2.5rem;
            }
          }
        }

        .image {
          flex-shrink: 0;
          width: 37%;
          overflow: hidden;
          border-radius: 0.5rem;

          @include responsive(in-md) {
            position: static !important;
            width: 100%;
          }
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
          gap: 3rem 0;
          width: 100%;

          @include responsive(in-md) {
            gap: 2rem 0;
            padding-bottom: 2rem;
          }

          h3 {
            font-size: 1.5rem;
            line-height: 1.4;

            @include responsive(in-md) {
              font-size: 1.25rem;
            }

            &::after {
              content: "";
              display: block;
              width: 3.5rem;
              height: 4px;
              margin-top: 1rem;
              background-color: $red;

              @include responsive(in-md) {
                margin-top: 0.5rem;
              }
            }
          }

          p {
            margin-top: 1.5rem;
            font-size: 1.125rem;
            line-height: 2;

            @include responsive(in-md) {
              margin-top: 1rem;
              font-size: 0.9375rem; /* stylelint-disable-line */
            }
          }
        }
      }

      .interview-toggle {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        border: solid 1px $red;
        cursor: pointer;

        &.active {
          margin-top: 3rem;

          @include responsive(in-md) {
            margin-top: 2rem;
          }

          .state span:nth-child(2) {
            transform: translate(-50%) rotate(0);
          }
        }

        .state {
          position: relative;
          width: 1.625rem;
          height: auto;
          aspect-ratio: 1/1;
          border-right: solid 1px $red;

          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.875rem;
            height: 1px;
            transition: all 300ms;
            background-color: $red;

            &:nth-child(1) {
              transform: translate(-50%);
            }

            &:nth-child(2) {
              transform: translate(-50%) rotate(90deg);
            }
          }
        }

        p {
          padding: 0 0.5rem;
          color: $red;
          font-size: 1.125rem;
        }
      }
    }
  }

  .requirements {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    h2 {
      color: $red;
      font-size: 3rem;

      @include responsive(in-md) {
        font-size: 1.5rem;
      }
    }

    h3 {
      padding-left: 0.5rem;
      border-left: solid 0.25rem $red;
      font-size: 1.5rem;

      @include responsive(in-md) {
        font-size: 1.125rem;
      }

      &.heading-full-time {
        margin-top: 2.5rem;

        @include responsive(in-md) {
          margin-top: 2rem;
        }
      }

      &.heading-part-time {
        margin-top: 5rem;

        @include responsive(in-md) {
          margin-top: 2rem;
        }
      }

      & + h4.sub-heading {
        margin-top: 1.5rem;
      }
    }

    h4.sub-heading {
      margin-top: 2.5rem;
      color: $red;
      font-size: 1.25rem;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        font-size: 1rem;
      }
    }

    &__table-wrapper {
      margin-top: 1rem;

      @include responsive(in-md) {
        margin-top: 0.5rem;
      }

      .table {
        display: table;
        width: 100%;
        border-collapse: collapse;

        .table-row {
          display: table-row-group;
        }

        .table-cell {
          display: table-cell;
          padding: 1rem;
          border: solid 1px $red;
          line-height: 1.4;

          @include responsive(in-md) {
            padding: 0.5rem;
            font-size: 0.875rem;
          }

          &:nth-child(1) {
            width: 20%;
            vertical-align: middle;

            @include responsive(in-md) {
              width: 23%;
            }
          }

          &:nth-child(2) {
            width: 80%;

            @include responsive(in-md) {
              width: 77%;
            }
          }
        }
      }
    }

    &__stop {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1rem;
      padding: 5rem 0;
      border-radius: 0.5rem;
      background-color: $gray;
    }
  }

  .faq {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    h2 {
      color: $red;
      font-size: 3rem;

      @include responsive(in-md) {
        font-size: 1.5rem;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 2rem 0;
      margin-top: 3rem;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        gap: 1.5rem 0;
      }

      & > li {
        display: flex;
        flex-direction: column;
        gap: 1rem 0;
        padding-bottom: 2rem;
        border-bottom: solid 1px $red;

        @include responsive(in-md) {
          gap: 0.75rem 0;
          padding-bottom: 1rem;
        }

        .question,
        .answer {
          display: flex;
          align-items: flex-start;
          gap: 0 0.75rem;

          span {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100vh;

            @include responsive(in-md) {
              width: 1.625rem;
              height: 1.625rem;
            }

            img {
              width: 1rem;

              @include responsive(in-md) {
                width: 0.625rem;
              }
            }
          }

          p {
            margin-top: 0.5rem;
            font-size: 1.125rem;
            line-height: 1.4;

            @include responsive(in-md) {
              margin-top: 0.125rem;
              font-size: 1rem;
            }
          }
        }

        .question > span {
          background-color: $red;
        }

        .answer > span {
          border: solid 1px $red;
          background-color: $white;
        }
      }
    }
  }

  .flow {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    h2 {
      color: $red;
      font-size: 3rem;

      @include responsive(in-md) {
        font-size: 1.5rem;
      }
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem 0;
      margin-top: 2.5rem;

      @include responsive(in-md) {
        margin-top: 1.5rem;
      }

      li {
        @include responsive(in-md) {
          border: solid 1px $red;
        }
      }

      .heading {
        display: flex;
        align-items: center;
        border: solid 1px $red;

        @include responsive(in-md) {
          flex-direction: column;
          border: unset;
        }

        .label {
          padding: 0.5rem 1rem;
          background-color: $red;
          color: $white;
          font-size: 1.5rem;

          @include responsive(in-md) {
            width: 100%;
            padding: 0.5rem;
            font-size: 0.875rem;
            letter-spacing: 0.1em;
            text-align: center;
          }

          span {
            font-size: 2.5rem;

            @include responsive(in-md) {
              font-size: 1.25rem;
            }
          }
        }

        h3 {
          padding-left: 1rem;
          font-size: 1.125rem;

          @include responsive(in-md) {
            width: 100%;
            margin-top: 1.5rem;
            padding: 0 1rem;
          }
        }
      }

      .explain {
        margin-top: 1rem;
        padding-left: 8.75rem;

        @include responsive(in-md) {
          margin-top: 0.75rem;
          padding: 0 1rem 1.5rem;
        }

        p {
          font-size: 1.125rem;
          line-height: 1.6;

          @include responsive(in-md) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .entry {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    h2 {
      color: $red;
      font-size: 3rem;

      @include responsive(in-md) {
        font-size: 1.5rem;
      }
    }

    &__main-content {
      margin-top: 2.5rem;
      padding: 2.5rem;
      border: solid 1px $green;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        padding: 2rem 1rem;
      }

      &--web {
        border: solid 1px $red;
      }

      p.explain {
        font-size: 1.125rem;
        line-height: 1.6;
        text-align: center;

        @include responsive(in-md) {
          font-size: 0.8125rem; /* stylelint-disable-line */
          text-align: left;
        }
      }

      p.heading {
        font-size: 1.25rem;
        text-align: center;

        @include responsive(in-md) {
          font-size: 1.125rem;
          line-height: 1.4;
        }
      }

      .tel-wrapper {
        width: fit-content;
        margin: 2rem auto 0;

        @include responsive(in-md) {
          margin-top: 1rem;
        }

        .tel-heading {
          font-size: 1.5rem;
          text-align: center;

          @include responsive(in-md) {
            font-size: 1.125rem;
          }
        }

        .tel-number {
          display: inline-block;
          margin-top: 0.5rem;
          color: $black;
          font-size: 4rem;
          pointer-events: none;

          @include responsive(in-md) {
            font-size: 2.5rem;
            pointer-events: all;
          }
        }

        .business-hour {
          margin-top: 0.5rem;
          padding: 0.25rem;
          background-color: $green;
          color: $white;
          text-align: center;

          @include responsive(in-md) {
            font-size: 0.75rem;
          }
        }
      }

      a.button {
        display: flex;
        align-items: center;
        gap: 0 1rem;
        width: fit-content;
        margin: 2.5rem auto 0;
        padding: 1rem 2rem;
        transition: all 300ms;
        border: solid 1px $red;
        color: $red;
        font-size: 1.125rem;

        @include responsive(in-md) {
          font-size: 1rem;
        }

        &:hover {
          background-color: $red;
          color: $white;
        }

        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          border: solid 1px $red;
          border-radius: 100vh;
          background-color: $white;

          @include responsive(in-md) {
            width: 2rem;
            height: 2rem;
          }

          img {
            width: 1rem;

            @include responsive(in-md) {
              width: 0.75rem;
            }
          }
        }
      }
    }
  }
}
