@use "../abstracts/base" as *;

.header.header--top {
  position: absolute;

  @include responsive(in-md) {
    position: sticky;
  }
}

.top {
  .fv {
    font-family: $font-shippori-mincho;

    content {
      position: relative;
    }

    &__slider {
      // splideの設定をしなくてはいけないので、このブロックのstylelintを無視
      /* stylelint-disable */
      .splide__slide {
        position: relative;
        width: 100%;
        height: 100vh;
        background-position: bottom;
        background-size: cover;

        @include responsive(in-md) {
          height: 18.75rem;
        }

        &--01 {
          background-image: url("../images/top/fv_slider-01.webp");

          @include responsive(in-md) {
            background-image: url("../images/top/fv_slider-01_sp.webp");
          }
        }

        &--02 {
          background-image: url("../images/top/fv_slider-02.webp");

          @include responsive(in-md) {
            background-image: url("../images/top/fv_slider-02_sp.webp");
          }
        }

        &--03 {
          background-image: url("../images/top/fv_slider-03.webp");

          @include responsive(in-md) {
            background-image: url("../images/top/fv_slider-03_sp.webp");
          }
        }

        &--04 {
          background-image: url("../images/top/fv_slider-04.webp");

          @include responsive(in-md) {
            background-image: url("../images/top/fv_slider-04_sp.webp");
          }
        }

        &--05 {
          background-image: url("../images/top/fv_slider-05.webp");

          @include responsive(in-md) {
            background-image: url("../images/top/fv_slider-05_sp.webp");
          }
        }
      }
      /* stylelint-enable */
    }

    &__pagination {
      display: flex;
      gap: 1.5rem;
      position: absolute;
      right: 2.5rem;
      bottom: 2.688rem;

      @include responsive(in-md) {
        gap: 1.25rem;
        right: unset;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
      }

      li {
        position: relative;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 100vh;
        background-color: $white;
        cursor: pointer;

        @include responsive(in-md) {
          width: 0.625rem;
          height: 0.625rem;
        }
      }
    }

    &__canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.75rem;
      height: 1.75rem;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    &__message {
      position: absolute;
      top: 22.5vh;
      left: 15%;

      @include responsive(in-md) {
        position: unset;
        padding: 3rem 5%;
        background-color: transparent;
      }

      h2 {
        font-size: 8.25vh;

        @include responsive(in-md) {
          font-size: 2rem;
        }
      }

      .text {
        margin-top: calc(7vh - 0.7em);
        margin-bottom: -0.7em;
        font-size: 2.25vh;
        line-height: 2.4;

        @include responsive(in-md) {
          margin-top: 1rem;
          font-size: 1rem;
          line-height: 1.6;
        }
      }

      h1 {
        margin-top: calc(7vh - 0.75em);
        font-size: 4.5vh;
        line-height: 1.4;

        @include responsive(in-md) {
          margin-top: 1.5rem;
          font-size: 1.25rem;
        }
      }
    }

    &__scroll-down {
      position: absolute;
      bottom: 0;
      left: 11.813rem;
      outline: none;
      color: $white;
      font-size: 2.25vh;

      @include responsive(in-md) {
        display: block;
        position: unset;
        width: fit-content;
        margin: 0 auto;
        color: $red;
        font-size: 1rem;
      }

      span {
        display: block;
        width: 1px;
        height: 7.5vh;
        margin-top: 1vh;
        margin-right: auto;
        margin-left: auto;
        background-color: $white;

        @include responsive(in-md) {
          height: 2.5rem;
          background-color: $red;
        }
      }
    }
  }

  .message {
    position: relative;
    z-index: 1;
    background-color: transparent;
    font-family: $font-shippori-mincho;

    &.container {
      max-width: unset;
    }

    .content {
      position: relative;
      max-width: 1260px;
      margin: 0 auto;
    }

    &__body {
      position: absolute;
      left: 28.063rem;

      @include responsive(in-md) {
        position: unset;
      }
    }

    &__title {
      position: relative;
      padding-top: 16.375rem;

      @include responsive(in-md) {
        margin-top: 5.125rem;
        padding: unset;
        padding-top: unset;
      }

      p {
        position: absolute;
        top: 9.5rem;
        color: $white;
        font-family: $font-lora;
        font-size: 8.75rem;

        @include responsive(in-md) {
          z-index: -1;
          top: -3rem;
          right: -5vw;
          font-size: 4.5rem;
        }
      }

      h2 {
        font-size: 2rem;
        line-height: 1.5;

        @include responsive(in-md) {
          font-size: 1.5rem;
          line-height: 1.4;
        }
      }
    }

    &__text {
      margin-top: calc(2.5rem - 0.25em - 0.5em);
      font-size: 1.125rem;
      line-height: 2;

      @include responsive(in-md) {
        margin-top: 0.5rem;
        font-size: 1rem;
      }
    }

    &__image {
      width: 48.438rem;
      margin-left: -11.563rem;

      @include responsive(in-md) {
        width: 100%;
        margin-top: 1rem;
        margin-left: unset;
      }
    }
  }

  .reason {
    margin-top: 0;
    font-family: $font-shippori-mincho;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    &__title {
      position: relative;
      padding-top: 4.313rem;

      @include responsive(in-md) {
        padding: unset;
      }

      p {
        position: absolute;
        z-index: 1;
        top: -2.625rem;
        color: $white;
        font-family: $font-lora;
        font-size: 8.75rem;

        @include responsive(in-md) {
          margin-left: -5vw;
          font-size: 4.5rem;
        }
      }

      h2 {
        position: relative;
        z-index: 1;
        font-size: 1.625rem;
        font-weight: $bold;

        @include responsive(in-md) {
          font-size: 1.75rem;
        }
      }
    }

    &__logo {
      position: absolute;
      right: -2.5rem;
      bottom: -9.125rem;
      width: 34rem;
    }

    &__media {
      display: flex;
      margin-top: 5rem;

      @include responsive(in-md) {
        flex-direction: column;
        margin-top: 2rem;
      }

      &--even {
        flex-direction: row-reverse;

        @include responsive(in-md) {
          flex-direction: column;
        }
      }
    }

    &__label {
      margin-top: 2.5rem;
      margin-right: 2.5rem;
      color: $red;
      font-family: $font-lora;
      font-size: 1.625rem;
      writing-mode: vertical-rl;

      @include responsive(in-md) {
        writing-mode: unset;
        margin-top: unset;
        margin-right: unset;
        font-size: 1.25rem;
      }

      &--even {
        margin-right: unset;
        margin-left: 2.5rem;

        @include responsive(in-md) {
          margin-left: unset;
        }
      }
    }

    &__body {
      flex-shrink: 0;
      margin-top: calc(2.5rem - 0.25em);
      margin-right: 5.875rem;

      @include responsive(in-md) {
        flex-shrink: unset;
        margin-top: 0;
        margin-right: 0;
      }

      &--even {
        margin-right: unset;
        margin-left: 3.375rem;

        @include responsive(in-md) {
          margin-left: unset;
        }
      }

      h3 {
        font-size: 2.5rem;
        line-height: 1.5;

        @include responsive(in-md) {
          margin-top: 0.5rem;
          font-size: 1.5rem;
        }
      }

      p {
        margin-top: 0.5rem;
        line-height: 2;

        @include responsive(in-md) {
          margin-top: 0.75rem;
        }
      }
    }

    &__image {
      position: relative;

      @include responsive(in-md) {
        margin-top: 0.5rem;
      }
    }
  }

  .service {
    margin-top: 4.625rem;
    padding-top: 4.625rem;
    padding-bottom: 5rem;
    background-color: $white;

    @include responsive(in-md) {
      margin-top: 3.5rem;
      padding-top: 3.5rem;
    }

    &.container {
      max-width: unset;
    }

    .content {
      max-width: 1260px;
      margin: 0 auto;
    }

    .decoration-text {
      position: relative;
      z-index: 1;
      color: $white-smoke;
      font-family: Lora, serif;
      font-size: 8.75rem;

      @include responsive(in-md) {
        margin-left: -5vw;
        font-size: 4.5rem;
      }
    }

    h2 {
      position: relative;
      z-index: 2;
      margin-top: -1.625rem;
      font-size: 1.625rem;
      font-weight: 700;

      @include responsive(in-md) {
        margin-top: -1.25rem;
        font-size: 1.75rem;
      }
    }

    &__works {
      display: flex;
      margin-top: 5rem;
      gap: 2.5rem;

      @include responsive(in-md) {
        flex-direction: column;
        margin-top: 2rem;
        gap: 2rem;
      }
    }

    &__item {
      display: flex;
      flex: 1;
      flex-direction: column;

      h3 {
        font-size: 2rem;

        @include responsive(in-md) {
          order: 2;
          margin-top: 1rem;
          font-size: 1.25rem;
        }
      }

      p {
        flex-grow: 1;
        margin-top: calc(1rem - 0.3em);
        line-height: 1.6;

        @include responsive(in-md) {
          order: 3;
          margin-top: 0.5rem;
        }
      }
    }

    &__image {
      margin-top: 1.5rem;

      @include responsive(in-md) {
        order: 1;
        margin-top: unset;
      }
    }

    &__content-link {
      display: flex;
      align-items: baseline;
      width: fit-content;
      margin-top: 2.5rem;
      margin-left: auto;
      gap: 0 1rem;

      @include responsive(in-md) {
        flex-direction: row-reverse;
        order: 4;
        margin-top: 0.5rem;
        gap: 0 0.5rem;
      }

      &:hover {
        p::after {
          width: 100%;
          animation-name: link-hover;
        }
      }

      p {
        position: relative;
        margin-top: 0;
        color: $black;

        @include responsive(in-md) {
          margin-top: 0;
          font-size: 0.875rem;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          animation-duration: 300ms;
          animation-timing-function: ease-in-out;
          background-color: $black;
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem;
        border: solid 1px $red;
        border-radius: 100vh;
        background-color: #fff;

        @include responsive(in-md) {
          width: 2rem;
          height: 2rem;
          padding: 0.5rem;
        }
      }
    }
  }

  .background-map {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $gray;
  }
}
