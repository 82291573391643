@use "../abstracts/base" as *;

.page-hero {
  max-width: unset !important;
  margin-top: 5rem;
  padding-right: unset !important;
  padding-left: unset !important;

  @include responsive(in-md) {
    margin-top: 1.5rem;
  }

  &__heading {
    h1 {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 5rem;
      font-size: 3.5rem;

      @include responsive(in-md) {
        padding: 0 5%;
        font-size: 2rem;
      }
    }

    .eng-sub {
      max-width: 1440px;
      margin-top: 2rem;
      margin-right: auto;
      margin-left: auto;
      padding: 0 5rem;
      font-size: 1.5rem;
      letter-spacing: 0.08em;

      @include responsive(in-md) {
        margin-top: 0.75rem;
        padding: 0 5%;
        font-size: 1.0625rem;
      }

      &::first-letter {
        color: $red;
      }
    }
  }

  &__main-visual {
    width: 100%;
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 2.5rem;
    }

    // バックグラウンドイメージに画像が適切に設定されていない時は、非表示にする（実績紹介ページ用の設定）
    &[style="background-image: url(..//);"]
    {
      display: none;
    }

    @include responsive(over-lg) {
      height: 540px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      img {
        display: none;
      }
    }
  }
}
