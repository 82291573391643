@use "../abstracts/base" as *;

.services-development-support {
  h2.normal-heading {
    line-height: 1.2;
  }

  // 共通するコンテンツのスタイル
  p.attention {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.6;

    @include responsive(in-md) {
      font-size: 0.75rem;
      line-height: 1.4;
    }
  }

  ul.point-list {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1.5rem;
    list-style-type: disc;
    border-radius: 0.5rem;
    background-color: $gray;
    gap: 1rem 0;

    @include responsive(in-md) {
      padding: 1rem;
      gap: 0.5rem 0;
    }

    & > li {
      margin-left: 1.5rem;
      color: $black;
      font-size: 1.125rem;
      line-height: 1.4;

      @include responsive(in-md) {
        font-size: 1rem;
      }
    }
  }

  .development-support-02,
  .development-support-03,
  .development-support-04,
  .development-support-05,
  .development-support-06,
  .development-support-07,
  .development-support-08,
  .development-support-09,
  .development-support-10,
  .development-support-11 {
    margin-top: 3.5rem;

    @include responsive(in-md) {
      margin-top: 2.5rem;
    }
  }

  // 個別のスタイル
  .development-support-01 {
    &__table {
      display: grid;
      grid-template-columns: 33.33%;
      grid-template-rows: 1fr;
      margin-top: 1.5rem;

      @include responsive(in-md) {
        grid-template-columns: 50%;
        grid-template-rows: auto auto auto 1rem auto auto 1rem auto auto;
        margin-top: 1rem;
      }

      .table-heading,
      .table-cell {
        padding: 1rem;
        border-bottom: solid 1px $line-gray;
        border-left: solid 1px $line-gray;
        font-size: 0.875rem;

        @include responsive(in-md) {
          font-size: 0.75rem;
        }
      }

      .table-heading {
        @include responsive(in-md) {
          padding: 0.75rem;
          background-color: $gray;
          font-size: 1rem;
          line-height: 1.2;
        }

        &--01 {
          display: flex;
          grid-column-end: 2;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-row-start: 1;
          align-items: center;
          border-top: solid 1px $line-gray;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-row-start: 1;
            justify-content: center;
            border-right: solid 1px $line-gray;
          }
        }

        &--02 {
          grid-column-end: 3;
          grid-column-start: 1;
          grid-row-end: 4;
          grid-row-start: 3;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 1;
            grid-row-end: 6;
            grid-row-start: 5;
            border-top: solid 1px $line-gray;
            border-right: solid 1px $line-gray;
            text-align: center;
          }
        }

        &--03 {
          grid-column-end: 3;
          grid-column-start: 1;
          grid-row-end: 5;
          grid-row-start: 4;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 1;
            grid-row-end: 9;
            grid-row-start: 8;
            border-top: solid 1px $line-gray;
            border-right: solid 1px $line-gray;
            text-align: center;
          }
        }
      }

      .table-cell {
        p {
          @include responsive(in-md) {
            text-align: center;
          }
        }

        &--01 {
          grid-column-end: 3;
          grid-column-start: 2;
          grid-row-end: 2;
          grid-row-start: 1;
          border-top: solid 1px $line-gray;

          @include responsive(in-md) {
            grid-column-end: 2;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-row-start: 2;
            border-top: unset;
            border-bottom: dashed 1px $line-gray;
          }
        }

        &--02 {
          grid-column-end: 3;
          grid-column-start: 2;
          grid-row-end: 3;
          grid-row-start: 2;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 2;
            grid-row-end: 3;
            grid-row-start: 2;
            border-right: solid 1px $line-gray;
            border-bottom: dashed 1px $line-gray;
          }
        }

        &--03 {
          grid-column-end: 4;
          grid-column-start: 3;
          grid-row-end: 2;
          grid-row-start: 1;
          border-top: solid 1px $line-gray;
          border-right: solid 1px $line-gray;

          @include responsive(in-md) {
            grid-column-end: 2;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-row-start: 3;
            border-top: unset;
            border-right: unset;
          }
        }

        &--04 {
          grid-column-end: 4;
          grid-column-start: 3;
          grid-row-end: 3;
          grid-row-start: 2;
          border-right: solid 1px $line-gray;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 2;
            grid-row-end: 4;
            grid-row-start: 3;
          }
        }

        &--05 {
          grid-column-end: 4;
          grid-column-start: 3;
          grid-row-end: 4;
          grid-row-start: 3;
          border-right: solid 1px $line-gray;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 1;
            grid-row-end: 7;
            grid-row-start: 6;
          }
        }

        &--06 {
          grid-column-end: 4;
          grid-column-start: 3;
          grid-row-end: 5;
          grid-row-start: 4;
          border-right: solid 1px $line-gray;

          @include responsive(in-md) {
            grid-column-end: 3;
            grid-column-start: 1;
            grid-row-end: 10;
            grid-row-start: 9;
          }
        }
      }
    }
  }

  .development-support-08 {
    &__table {
      display: table;
      width: 100%;
      margin-top: 1.5rem;
      border-collapse: collapse;

      @include responsive(in-md) {
        margin-top: 2rem;
      }

      .table-header {
        display: table-header-group;
        font-weight: $bold;

        .table-cell {
          padding: 0.75rem 0.5rem;

          @include responsive(in-md) {
            font-size: 0.875rem;
          }
        }
      }

      .table-row {
        display: table-row;
        width: 100%;
      }

      .table-cell {
        display: table-cell;
        width: 20.5%;
        padding: 0.75rem;
        border: solid 1px $line-gray;
        font-size: 0.875rem;

        @include responsive(in-md) {
          width: 33%;
          padding: 0.5rem;
          font-size: 0.75rem;
          line-height: 1.4;
          vertical-align: middle;
        }

        &:first-of-type {
          width: 79.5%;

          @include responsive(in-md) {
            width: 67%;
          }
        }
      }
    }
  }
}
