@use "../abstracts/base" as *;

.services-surveying {
  .surveying-01 {
    &__flow {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;
      margin-top: 1.5rem;

      & > li.card {
        padding: 1.5rem;
        border-radius: 0.5rem;
        background-color: $light-red;
        counter-increment: number;

        @include responsive(in-md) {
          padding: 1.5rem 1rem;
        }

        h4 {
          display: flex;
          align-items: center;
          font-size: 1.5rem;

          @include responsive(in-md) {
            font-size: 1.125rem;
          }

          &::before {
            content: counter(number);
            display: block;
            margin-right: 0.5rem;
            padding-right: 0.5rem;
            border-right: solid 1px $black;

            @include responsive(in-md) {
              margin-right: 0.375rem;
              padding-right: 0.375rem;
            }
          }
        }

        p.explain {
          margin-top: 1rem;
          font-size: 1.125rem;
          line-height: 1.4;

          @include responsive(in-md) {
            margin-top: 0.75rem;
            font-size: 0.875rem;
          }
        }

        .tel {
          display: flex;
          gap: 0 0.25rem;
          align-items: center;
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: solid 1px $line-gray;

          @include responsive(in-md) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0.75rem;
            padding-top: 0.75rem;
          }

          .tel-heading {
            transform: translateY(10%);
            font-weight: $bold;

            @include responsive(in-md) {
              transform: unset;
              font-size: 0.875rem;
            }
          }

          & > a {
            display: inline-block;
            color: $black;
            font-size: 2rem;
            pointer-events: none;

            @include responsive(in-md) {
              margin-top: 0.5rem;
              pointer-events: all;
            }
          }

          .business-hour {
            transform: translateY(15%);
            font-size: 0.75rem;

            @include responsive(in-md) {
              margin-top: 0.25rem;
              transform: unset;
            }
          }
        }
      }

      & > li.arrow {
        div {
          clip-path: polygon(0 0, 50% 100%, 100% 0);
          width: 1.5rem;
          height: 0.75rem;
          margin: 0 auto;
          background-color: $red;
        }
      }
    }
  }

  .surveying-02 {
    margin-top: 3.5rem;

    @include responsive(in-md) {
      margin-top: 2.5rem;
    }

    &__service-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1.5rem;

      @include responsive(in-md) {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      & > li {
        width: calc(50% - 0.5rem);
        padding: 1.5rem;
        border: solid 1px $red;

        @include responsive(in-md) {
          width: 100%;
          padding: 1.5rem 1rem;
        }

        h3 {
          color: $red;
          font-size: 1.25rem;
          text-align: center;
        }

        p {
          margin-top: 0.5rem;
          line-height: 1.6;
          word-break: break-all;
        }
      }
    }
  }
}
