@use "../abstracts/base" as *;

.services-civil-engineering {
  .civil-engineering-02,
  .civil-engineering-03 {
    margin-top: 3.5rem;

    @include responsive(in-md) {
      margin-top: 2.5rem;
    }
  }
}
