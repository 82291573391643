@use "../abstracts/base" as *;

.company {
  // start: 代表メッセージ
  .company-message {
    margin-top: 5rem;
    margin-bottom: 7.5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;
    }

    h2 {
      position: relative;
      color: $red;
      font-size: 2.5rem;
      text-align: center;

      @include responsive(in-md) {
        font-size: 2rem;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: 50%;
        width: 1px;
        height: 2rem;
        transform: translateY(100%);
        background-color: $red;

        @include responsive(in-md) {
          display: none;
        }
      }
    }

    &__main-message {
      position: relative;
      margin-top: 4rem;

      @include responsive(in-md) {
        margin-top: 1rem;
      }

      p {
        font-family: $font-shippori-mincho;
        font-size: 1.5rem;
        line-height: 1.6;
        text-align: center;

        @include responsive(in-md) {
          font-size: 1.25rem;
        }
      }

      .decoration-text {
        position: absolute;
        z-index: -1;
        bottom: -0.6rem;
        left: 50%;
        width: 37.375rem;
        transform: translateX(-50%);

        @include responsive(in-md) {
          top: 0;
          width: 17.938rem;
          transform: translateX(-50%) translateY(-30%);
        }
      }
    }

    &__content {
      display: flex;
      margin-top: 2rem;
      gap: 3.6%;

      @include responsive(in-md) {
        flex-direction: column;
        margin-top: 1rem;
      }
    }

    &__wrapper {
      width: 59%;

      @include responsive(in-md) {
        width: 100%;
        margin-top: 0.75rem;
      }
    }

    &__image {
      width: 37.5%;

      @include responsive(in-md) {
        width: 100%;
      }
    }

    &__text {
      font-size: 1.125rem;
      line-height: 1.8;

      @include responsive(in-md) {
        width: 100%;
        font-size: 1rem;
      }
    }

    &__name {
      margin-top: 1.5rem;
      font-size: 1.5rem;
      text-align: end;

      span {
        margin-right: 0.25rem;
        font-size: 1rem;
      }
    }
  }
  // end: 代表メッセージ
  // start: 経営理念・行動指針
  .vision {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $gray;
    background-image: url("../images/company/company_background.webp");
    background-repeat: no-repeat;
    background-size: cover;

    @include responsive(in-md) {
      padding-top: 3.5rem;
      padding-right: 5%;
      padding-bottom: 3.5rem;
      padding-left: 5%;
    }

    &.container {
      max-width: unset;
    }

    .content {
      position: relative;
      width: 50rem;
      max-width: 900px;
      margin: 0 auto;

      @include responsive(in-md) {
        width: 100%;
      }
    }

    h2 {
      color: $red;
      font-size: 2.5rem;
      text-align: center;

      @include responsive(in-md) {
        font-size: 2rem;
      }
    }

    h3 {
      font-size: 2rem;
      text-align: center;

      @include responsive(in-md) {
        font-size: 1.5rem;
      }
    }

    &__philosophy {
      margin-top: 3.5rem;

      @include responsive(in-md) {
        margin-top: 2.5rem;
      }
    }

    &__subject {
      margin-top: 0.5rem;
      color: $red;
      font-size: 1.125rem;
      text-align: center;

      @include responsive(in-md) {
        margin-top: 0.25rem;
        font-size: 0.875rem;
      }
    }

    &__list {
      margin-top: 2rem;

      @include responsive(in-md) {
        margin-top: 2.5rem;
      }
    }

    &__item {
      display: flex;
      gap: 1%;

      @include responsive(in-md) {
        flex-direction: column;
        gap: 0.5rem;
        padding: 1.5rem 1rem;
        border-top: solid 1px $line-gray;
      }

      &:last-child {
        @include responsive(in-md) {
          border-bottom: solid 1px $line-gray;
        }

        p {
          border-bottom: solid 1px $line-gray;

          @include responsive(in-md) {
            border: unset;
          }
        }
      }

      p {
        padding: 1rem;
        border-top: solid 1px $line-gray;
        line-height: 1.4;

        @include responsive(in-md) {
          padding: unset;
          border: unset;
        }

        &:first-child {
          display: flex;
          align-items: center;
          width: 30%;

          @include responsive(in-md) {
            width: 100%;
            font-weight: bold;
          }
        }

        &:last-child {
          width: 69%;

          @include responsive(in-md) {
            width: 100%;
          }
        }
      }
    }

    &__text {
      margin-top: 2rem;
      font-family: $font-shippori-mincho;
      font-size: 1.5rem;
      line-height: 2;
      text-align: center;

      @include responsive(in-md) {
        font-size: 1rem;
      }
    }

    &__action {
      margin-top: 3.5rem;
      font-family: $font-shippori-mincho;

      @include responsive(in-md) {
        margin-top: 2.5rem;
      }

      table {
        width: 100%;
        margin-top: 2rem;

        @include responsive(in-md) {
          margin-top: 1.5rem;
          border-top: solid 1px $line-gray;
          border-bottom: solid 1px $line-gray;
        }

        tr {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          @include responsive(in-md) {
            flex-direction: column;
            align-items: unset;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
          }

          &:last-of-type {
            td {
              border-bottom: solid 1px $line-gray;

              @include responsive(in-md) {
                border-bottom: unset;
              }
            }
          }

          & + tr {
            @include responsive(in-md) {
              border-top: solid 1px $line-gray;
            }
          }
        }

        td {
          display: flex;
          align-items: center;
          height: 4.75rem;
          padding-right: 1rem;
          padding-left: 1rem;
          border-top: solid 1px $line-gray;
          line-height: 1.4;

          @include responsive(in-md) {
            height: auto;
            border-top: unset;
          }

          &:first-of-type {
            width: 30%;

            @include responsive(in-md) {
              width: 100%;
              font-weight: $bold;
            }
          }

          &:last-of-type {
            width: 70%;

            @include responsive(in-md) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  // end: 経営理念・行動指針
  // start: 会社概要
  .overview {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    a {
      color: $black;
    }

    .content {
      width: 50rem;
      max-width: 900px;
      margin: 0 auto;

      @include responsive(in-md) {
        width: 100%;
      }
    }

    h2 {
      color: $red;
      font-size: 2.5rem;
      text-align: center;

      @include responsive(in-md) {
        font-size: 2rem;
      }
    }

    table {
      width: 100%;
      margin-top: 2.5rem;
      border-collapse: collapse;
      border: solid 1px $line-gray;

      @include responsive(in-md) {
        margin-top: 2rem;
      }

      tr {
        border: solid 1px $line-gray;
      }

      td {
        padding: 1rem;
        line-height: 1.4;

        @include responsive(in-md) {
          padding: 0.75rem 0.5rem;
          font-size: 0.875rem;
        }

        &:first-of-type {
          width: 30%;
          background-color: #fff3ef;
        }

        &:last-of-type {
          width: 70%;
        }
      }

      .entry-number {
        li {
          line-height: 1.6;

          @include responsive(in-md) {
            font-size: 0.75rem;
          }
        }
      }

      .project {
        li {
          position: relative;
          margin-top: 0.5rem;
          padding-left: 1em;

          @include responsive(in-md) {
            font-size: 0.75rem;
            line-height: 1.2;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            width: 0.25rem;
            height: 0.25rem;
            transform: translate(-50%, 50%);
            border-radius: 100vh;
            background-color: $black;

            @include responsive(in-md) {
              width: 0.188rem;
              height: 0.188rem;
              transform: translate(-50%, 0);
            }
          }

          &:first-of-type {
            margin-top: 0;
          }
        }
      }

      .license {
        li {
          margin-top: 0.5rem;

          @include responsive(in-md) {
            margin-top: 0.25rem;
            font-size: 0.75rem;
            line-height: 1.2;
          }

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }

    &__map {
      margin-top: 1rem;

      @include responsive(in-md) {
        margin-top: 0.5rem;
      }

      iframe {
        width: 20rem;
        height: 12.5rem;

        @include responsive(in-md) {
          width: 100%;
          height: 7.688rem;
        }
      }
    }
  }
  // end: 会社概要
  // start: 認証取得, 取り組み
  .certification,
  .contribution {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    .content {
      width: 50rem;
      max-width: 900px;
      margin: 0 auto;

      @include responsive(in-md) {
        width: 100%;
      }
    }

    h2 {
      color: $red;
      font-size: 2.5rem;
      text-align: center;

      @include responsive(in-md) {
        font-size: 2rem;
      }
    }

    &__collection {
      margin-top: 2.5rem;

      @include responsive(in-md) {
        margin-top: 2rem;
      }
    }

    h3 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1rem;
      border-left: solid 0.25rem $red;
      font-size: 1.125rem;
      line-height: 1.42;

      @include responsive(in-md) {
        font-size: 1rem;
        line-height: 1.4;
      }
    }

    &__description {
      margin-top: 1rem;
      line-height: 1.4;

      @include responsive(in-md) {
        font-size: 0.875rem;
      }
    }

    p.table-heading {
      margin-top: 2rem;
      font-weight: $bold;

      @include responsive(in-md) {
        margin-top: 1.5rem;
        font-size: 0.875rem;
      }
    }

    table {
      width: 100%;
      margin-top: 1rem;
      border-collapse: collapse;
      border: solid 1px $line-gray;

      tr {
        border: solid 1px $line-gray;
      }

      td {
        padding: 1rem;
        line-height: 1.4;
        vertical-align: top;

        @include responsive(in-md) {
          padding: 0.75rem 0.5rem;
          font-size: 0.875rem;
          line-height: 1.2;
        }

        &:first-of-type {
          width: 30%;
          background-color: #fff3ef;
        }

        &:last-of-type {
          width: 70%;

          @include responsive(in-md) {
            vertical-align: middle;
          }
        }
      }
    }

    &__logo {
      height: 5rem;

      @include responsive(in-md) {
        height: 3.5rem;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    &__text {
      margin-top: 1rem;

      @include responsive(in-md) {
        line-height: 1.4;
      }
    }
  }
  // end: 認証取得
  // start: 沿革
  .history {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 5rem;
    padding-right: 5rem;
    padding-bottom: 5rem;
    padding-left: 5rem;
    background-color: $gray;

    @include responsive(in-md) {
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;
      padding-top: 3.5rem;
      padding-right: 5%;
      padding-bottom: 3.5rem;
      padding-left: 5%;
    }

    &.container {
      max-width: unset;
    }

    .content {
      position: relative;
      max-width: 1260px;
      margin: 0 auto;
    }

    h2 {
      color: $red;
      font-size: 2.5rem;
      text-align: center;

      @include responsive(in-md) {
        font-size: 2rem;
      }
    }

    &__group {
      margin-top: 3.5rem;
      border-top: solid 1px $red;
      border-bottom: solid 1px $red;

      @include responsive(in-md) {
        margin-top: 2.5rem;
      }
    }

    &__item {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      background-image: linear-gradient(to right, $red 0.5rem, transparent 0.5rem);
      background-repeat: repeat-x;
      background-position: left bottom;
      background-size: 1rem 1px;

      @include responsive(in-md) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background-image: linear-gradient(to right, $red 0.313rem, transparent 0.313rem);
        background-size: 0.625rem 1px;
      }

      &:last-of-type {
        background: unset;
      }
    }

    &__year {
      color: $red;
      font-size: 2.5rem;

      @include responsive(in-md) {
        font-size: 1.5rem;
        line-height: 1.4;
      }
    }

    &__detail {
      display: flex;
      margin-top: 1rem;
      gap: 0.5rem;

      @include responsive(in-md) {
        flex-direction: column;
      }

      p {
        &:first-of-type {
          width: 5.625rem;
          color: $red;
          font-size: 1.125rem;

          @include responsive(in-md) {
            font-size: 1rem;
            line-height: 1.4;
          }
        }

        &:last-of-type {
          font-size: 1.125rem;

          @include responsive(in-md) {
            font-size: 0.875rem;
            line-height: 1.6;
          }
        }
      }
    }
  }
  // end: 沿革
}
