@use "../abstracts/base" as *;

// サービス紹介ページの共通パーツのスタイル
.services-normal {
  .services-main-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 0 5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include responsive(in-md) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
  }

  .in-page-navigation {
    position: sticky;
    top: 2.5rem;
    flex-shrink: 0;
    width: 28.5%;
    font-size: 1.125rem;

    @include responsive(in-md) {
      display: none;
    }

    & > li {
      border-bottom: solid 1px $red;

      &:first-child {
        border-top: solid 1px $red;
      }

      & > p {
        padding: 1rem;
        background-color: $red;
        color: $white;
      }

      & > a {
        display: block;
        padding: 1rem;
        transition: all 300ms;
        color: $black;

        &:hover {
          background-color: $red;
          color: $white;
        }
      }

      .sub-navigation,
      .sub-navigation-child {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        gap: 1rem 0;

        & > li {
          position: relative;
          padding-left: 1.75rem;
        }

        & > li > a {
          display: inline-block;
          position: relative;
          transition: all 300ms;
          color: $black;

          &:hover {
            color: $red;
          }
        }
      }

      .sub-navigation {
        padding-bottom: 1rem;

        & > li > a {
          &:hover {
            &::before {
              width: 1rem;
            }
          }

          &.active {
            color: $red;

            &::before {
              width: 1rem;
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -1.75rem;
            width: 0;
            height: 2px;
            transform: translateY(-50%);
            transition: all 300ms;
            background-color: $red;
          }
        }
      }

      .sub-navigation-child {
        & > li {
          padding-left: 1em;
        }

        & > li > a {
          color: $black;

          &::before {
            left: calc(-1.75rem - 1em);
          }
        }
      }
    }
  }

  .main-content {
    width: 100%;

    // コンテンツの見出し
    h2.normal-heading {
      padding-bottom: 1.5rem;
      border-bottom: solid 4px $red;
      color: $red;
      font-size: 2rem;

      @include responsive(in-md) {
        padding-bottom: 1rem;
        font-size: 1.5rem;
      }
    }

    &__service-image {
      margin-top: 1.5rem;
    }

    &__explain {
      margin-top: 1rem;
      font-size: 1.125rem;
      line-height: 1.6;

      @include responsive(in-md) {
        margin-top: 0.5rem;
        font-size: 1rem;
      }
    }

    // コンテンツ内の小見出し
    h3.sub-heading {
      margin-top: 2rem;
      padding-left: 0.5rem;
      border-left: solid 5px $red;
      font-size: 1.5rem;
      line-height: 1.4;

      @include responsive(in-md) {
        font-size: 1.125rem;
      }
    }
  }

  // 関連するページへのリンク
  .relation-link {
    margin-top: 3.5rem;

    @include responsive(in-md) {
      margin-top: 2.5rem;
    }

    & > p {
      font-size: 1.5rem;
      line-height: 1.3;
      text-align: center;

      @include responsive(in-md) {
        font-size: 1rem;
      }
    }

    a {
      display: flex;
      position: relative;
      width: fit-content;
      margin: 1rem auto 0;
      padding: 1.5rem 3.5rem;
      transition: all 300ms;
      background-color: $red;

      @include responsive(in-md) {
        padding: 1rem 2.5rem;
      }

      &:hover {
        opacity: 0.6;
      }

      p {
        font-size: 1.5rem;

        @include responsive(in-md) {
          font-size: 1.125rem;
        }
      }

      .arrow {
        position: absolute;
        top: 50%;
        right: 1rem;
        width: 0.625rem;
        height: auto;
        transform: translateY(-50%);

        @include responsive(in-md) {
          width: 0.5rem;
        }
      }
    }
  }

  // ページの下部の実績と電話番号のリンク
  .services-bottom-link {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }

    &__works {
      display: block;
      position: relative;
      overflow: hidden;

      &:hover {
        .image {
          transform: scale(1.1);
        }
      }

      .image {
        position: relative;
        transition: all 300ms;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      .link-content {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        & > p {
          font-size: 1.125rem;

          @include responsive(in-md) {
            font-size: 1rem;
          }
        }

        .button {
          position: relative;
          margin-top: 2rem;
          padding: 1.125rem 5rem;
          border: solid 1px $white;
          font-size: 1.25rem;

          @include responsive(in-md) {
            margin-top: 1.5rem;
            font-size: 1.125rem;
          }

          span {
            position: absolute;
            top: 50%;
            right: 1rem;
            width: 0.5rem;
            transform: translateY(-50%);

            @include responsive(in-md) {
              right: 0.75rem;
              width: 0.375rem;
            }
          }
        }
      }
    }

    &__tel-wrapper {
      margin-top: 1.5rem;
      padding: 2.5rem;
      border: solid 2px $green;

      @include responsive(in-md) {
        margin-top: 1rem;
        padding: 1.5rem;
      }

      h2 {
        font-size: 1.25rem;
        text-align: center;

        @include responsive(in-md) {
          font-size: 1.125rem;
          line-height: 1.4;
        }
      }

      .tel-wrapper {
        width: fit-content;
        margin: 1.5rem auto 0;

        @include responsive(in-md) {
          margin-top: 1rem;
        }

        .tel-number {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0 1rem;

          @include responsive(in-md) {
            flex-direction: column;
          }

          p {
            transform: translateY(15%);
            font-size: 2rem;

            @include responsive(in-md) {
              transform: unset;
              font-size: 1rem;
            }
          }

          a {
            display: inline-block;
            color: $black;
            font-size: 4.5rem;

            @include responsive(in-md) {
              margin-top: 0.5rem;
              font-size: 2.5rem;
            }
          }
        }

        .business-hour {
          width: 100%;
          margin-top: 0.5rem;
          padding: 0.25rem;
          background-color: $green;
          color: $white;
          font-size: 1.25rem;
          text-align: center;

          @include responsive(in-md) {
            margin-top: 0.25rem;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
