@use "../abstracts/base" as *;

.services-registration {
  .registration-01 {
    &__land,
    &__building {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;
      margin-top: 1rem;

      & > li {
        padding: 1.5rem;
        border: solid 1px $red;

        @include responsive(in-md) {
          padding: 1.5rem 1rem;
        }

        h4 {
          padding-bottom: 1rem;
          border-bottom: solid 1px $red;
          color: $red;
          font-size: 1.125rem;
        }

        .main-content {
          display: flex;
          align-items: center;
          gap: 0 2.5rem;
          margin-top: 1.5rem;

          @include responsive(in-md) {
            flex-direction: column;
            margin-top: 1rem;
            gap: 1rem 0;
          }

          .image {
            flex-shrink: 0;
            width: 13.75rem;

            @include responsive(in-md) {
              width: auto;
              height: 7.5rem;

              img {
                width: auto;
                height: 100%;
              }
            }

            &.type-07 {
              @include responsive(in-md) {
                height: 4.5rem;
              }
            }
          }

          .explain {
            line-height: 1.4;

            small {
              font-size: 0.625rem;
            }

            span.attention {
              display: inline-block;
              margin-top: 0.5rem;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
