$font-shippori-mincho: "Shippori Mincho", serif;
$font-noto-sans: 'Noto Sans JP', sans-serif;
$font-lora: "Lora", serif;
$font-robot: "Roboto", sans-serif;

$black: #000;
$white: #fff;
$gray: #edf0ef;
$dark-gray: #898989;
$light-gray: #efefef;
$line-gray: #b1b1b1;
$white-smoke: #f5f5f5;
$red: #db0400;
$light-red: #fff3ef;
$green: #00b292;
$light-green: #e6f6f4;

// font weight
$regular: 400;
$medium: 500;
$bold: 700;
