@use "../abstracts/base" as *;

.services {
  // start: about us
  .about {
    .content {
      position: relative;
      padding: 7.5rem 0;

      @include responsive(in-md) {
        padding: 3.5rem 0 0;
      }
    }

    &__decoration-text {
      position: absolute;
      top: 0;
      left: -5rem;
      transform: translateY(-50%);
      color: $red;
      font-family: $font-lora;
      font-size: 7.5rem;

      @include responsive(in-md) {
        left: -5vw;
        font-size: 4rem;
      }
    }

    &__main-wrapper {
      &__text-wrapper {
        width: 50%;

        @include responsive(in-md) {
          width: 100%;
        }

        h2 {
          font-size: 2rem;
          line-height: 1.4;

          @include responsive(in-md) {
            font-size: 1.625rem;
          }
        }

        p {
          margin-top: 1.5rem;
          font-size: 1.125rem;
          line-height: 2;

          @include responsive(in-md) {
            font-size: 1rem;
          }
        }
      }

      &__image-wrapper {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include responsive(in-md) {
          position: static;
          width: 100vw;
          margin-top: 2.5rem;
          margin-left: -5vw;
        }

        img {
          position: absolute;
          right: -5rem;
          width: auto;
          height: 100%;

          @include responsive(in-md) {
            position: static;
            right: unset;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  // end: about us
  // start: service-link
  .service-link {
    max-width: unset;
    background-color: $gray;

    .content {
      max-width: 1260px;
      margin: 0 auto;
      padding: 5rem 0;

      @include responsive(in-md) {
        padding: 3.5rem 0;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      gap: 2.5rem 0;

      @include responsive(in-md) {
        gap: 2rem 0;
      }

      li {
        position: relative;
        overflow: hidden;

        @include responsive(in-md) {
          padding: 1.5rem 5vw;
          background-color: $red;
        }

        a {
          &:hover {
            .image {
              transform: scale(1.1);

              @include responsive(in-md) {
                transform: unset;
              }
            }
          }
        }

        // .text-wrapperの外側でも使用するので、h2はここで定義する
        h2 {
          color: $white;
          font-size: 1.5rem;

          @include responsive(in-md) {
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
              margin-right: 0.75rem;

              &::after {
                content: "";
                display: block;
                width: 1px;
                height: 2rem;
                margin-left: 0.75rem;
                background-color: $white;
              }
            }
          }
        }

        .image {
          position: relative;
          transition: all 300ms;

          @include responsive(in-md) {
            margin-top: 1rem;
          }

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            mix-blend-mode: multiply;

            @include responsive(in-md) {
              display: none;
            }
          }
        }

        .service-details {
          display: flex;
          position: absolute;
          top: 50%;
          left: 0;
          align-items: center;
          width: 49%;
          padding: 2.5rem;
          transform: translateY(-50%);
          background-color: $red;
          gap: 0 1.5rem;

          @include responsive(in-md) {
            position: static;
            width: 100%;
            margin-top: 1rem;
            padding: unset;
            transform: unset;
            background-color: unset;
          }

          .number {
            font-family: $font-shippori-mincho;
            font-size: 4rem;
          }

          .text-wrapper {
            .heading {
              display: flex;
              align-items: center;
              gap: 0 0.5rem;

              @include responsive(in-md) {
                display: none;
              }

              .arrow {
                width: 1.25rem;
              }
            }

            p {
              margin-top: 1rem;
              line-height: 1.4;

              @include responsive(in-md) {
                margin-top: unset;
              }
            }
          }
        }

        .button {
          @include responsive(in-md) {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 0.5rem;
            margin-top: 1rem;
            padding: 1rem;
            background-color: $white;

            p {
              color: $red;
            }

            .arrow {
              width: 1rem;
            }
          }
        }
      }
    }
  }
  // end: service-link
  // start: common-worry
  .common-worry {
    margin-top: 5rem;

    @include responsive(in-md) {
      margin-top: 3.5rem;
    }
  }
  // end: common-worry
}
