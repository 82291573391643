@use "../abstracts/base" as *;

.common-recruit {
  &.container {
    padding: 2.5rem 5rem;
    background-color: $red;

    @include responsive(in-md) {
      padding: 2.5rem 5%;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    @include responsive(in-md) {
      flex-direction: column-reverse;
    }

    .decoration-text {
      color: $white-smoke;
      font-family: $font-shippori-mincho;
      font-size: 8.75rem;

      @include responsive(in-md) {
        font-size: 3rem;
      }
    }
  }

  &__content {
    width: 48%;

    @include responsive(in-md) {
      width: 100%;
    }

    h2 {
      margin-top: 1rem;
      color: $white;
      font-size: 2.25rem;
      line-height: 1.5;

      @include responsive(in-md) {
        margin-top: 0.5rem;
        font-size: 1.75rem;
        line-height: 1.4;
      }
    }

    .message-text {
      margin-top: 1rem;
      color: $white;
      font-size: 1.125rem;
      line-height: 1.6;

      @include responsive(in-md) {
        margin-top: 0.5rem;
        font-size: 1rem;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 0 1rem;
      width: fit-content;
      margin-top: 2.5rem;
      margin-left: auto;

      @include responsive(in-md) {
        gap: 0 0.5rem;
        margin-top: 1rem;
      }

      &:hover {
        p::after {
          width: 100%;
          animation-name: link-hover;
        }
      }

      p {
        position: relative;
        color: $white;

        @include responsive(in-md) {
          font-size: 0.875rem;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          animation-duration: 300ms;
          animation-timing-function: ease-in-out;
          background-color: $white;
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem;
        border: solid 1px $red;
        border-radius: 100vh;
        background-color: #fff;

        @include responsive(in-md) {
          width: 2rem;
          height: 2rem;
          padding: 0.5rem;
        }
      }
    }
  }

  &__image {
    width: 45%;

    @include responsive(in-md) {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
