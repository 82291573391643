.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

:focus {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

legend {
  *margin-left: -7px;
  padding: 0;
  border: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  *overflow: visible;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  *width: 13px;
  *height: 13px;
  padding: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

@keyframes link-hover {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .header {
    background-color: #fff;
  }
}
.header.container {
  position: relative;
  z-index: 99;
  max-width: unset;
  margin-top: 2.5rem;
  padding: 0 2.5rem;
}
@media screen and (max-width: 960px) {
  .header.container {
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    margin-top: unset;
    padding: 0;
    border-bottom: solid 1px #db0400;
  }
}
.header--top .header__logo {
  pointer-events: none;
}
.header--top .header__pc-navigation > ul > .top > a {
  color: #db0400;
}
.header--top .header__pc-navigation > ul > .top > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  background-color: #db0400;
}
.header--services .header__pc-navigation > ul > .services > a {
  color: #db0400;
}
.header--services .header__pc-navigation > ul > .services > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  background-color: #db0400;
}
.header--works .header__pc-navigation > ul > .works > a {
  color: #db0400;
}
.header--works .header__pc-navigation > ul > .works > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  background-color: #db0400;
}
.header--company .header__pc-navigation > ul > .company > a {
  color: #db0400;
}
.header--company .header__pc-navigation > ul > .company > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  background-color: #db0400;
}
.header--recruit .header__pc-navigation > ul > .recruit > a {
  color: #db0400;
}
.header--recruit .header__pc-navigation > ul > .recruit > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  background-color: #db0400;
}
.header .content {
  display: flex;
  align-items: flex-start;
}
.header__logo {
  width: 5.625rem;
}
@media screen and (max-width: 960px) {
  .header__logo {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    height: 2.375rem;
    transform: translateY(-50%);
  }
  .header__logo img {
    width: auto;
    height: 100%;
  }
}
.header__logotype {
  display: none;
}
@media screen and (max-width: 960px) {
  .header__logotype {
    display: block;
    width: auto;
    height: 1.875rem;
  }
  .header__logotype img {
    width: auto;
    height: 100%;
  }
}
.header__pc-navigation {
  display: flex;
  gap: 0 2.5rem;
  margin-left: auto;
}
@media screen and (max-width: 960px) {
  .header__pc-navigation {
    display: none;
  }
}
.header__pc-navigation ul.main-navigation {
  display: flex;
  align-items: center;
  gap: 0 2rem;
  height: fit-content;
  padding-top: 0.5rem;
}
.header__pc-navigation ul.main-navigation > li {
  padding-bottom: 1rem;
}
.header__pc-navigation ul.main-navigation > li a {
  display: inline-block;
  position: relative;
  width: fit-content;
  padding-bottom: 0.25rem;
  transition: all 300ms;
  color: #000;
}
.header__pc-navigation ul.main-navigation > li a:hover {
  color: #db0400;
}
.header__pc-navigation ul.main-navigation > li a:hover::after {
  transform: scaleX(1);
  transform-origin: left center;
}
.header__pc-navigation ul.main-navigation > li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 300ms;
  background-color: #db0400;
}
.header__pc-navigation ul.main-navigation li.services {
  position: relative;
}
.header__pc-navigation ul.main-navigation li.services:hover ul {
  opacity: 1;
  pointer-events: all;
}
.header__pc-navigation ul.main-navigation li.services ul {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  flex-direction: column;
  width: fit-content;
  padding: 1.5rem;
  transform: translate(-50%, 100%);
  transition: all 300ms;
  border-radius: 0.5rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  opacity: 0;
  background-color: #fff;
  pointer-events: none;
  gap: 1.25rem 0;
}
.header__pc-navigation ul.main-navigation li.services ul:hover {
  opacity: 1;
  pointer-events: all;
}
.header__pc-navigation ul.main-navigation li.services ul a {
  white-space: nowrap;
}
@media screen and (max-width: 960px) {
  .header__pc-navigation .pc-tel-info {
    display: none;
  }
}
.header__pc-navigation .pc-tel-info .number span {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 1.125rem;
}
.header__pc-navigation .pc-tel-info .number a {
  color: #000;
  font-size: 2rem;
  pointer-events: none;
}
.header__pc-navigation .pc-tel-info .business-hour {
  margin-top: 0.5rem;
  text-align: right;
}
.header__navigation-toggle {
  display: none;
}
@media screen and (max-width: 960px) {
  .header__navigation-toggle {
    display: block;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.header__navigation-toggle.active .border span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.header__navigation-toggle.active .border span:nth-child(2) {
  display: none;
}
.header__navigation-toggle.active .border span:nth-child(3) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}
.header__navigation-toggle .border {
  position: relative;
  width: 2rem;
  height: 1.125rem;
}
.header__navigation-toggle .border span {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #db0400;
}
.header__navigation-toggle .border span:nth-child(1) {
  top: 0;
}
.header__navigation-toggle .border span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.header__navigation-toggle .border span:nth-child(3) {
  bottom: 0;
  transform: translateY(-50%);
}
.header__navigation-toggle p {
  margin-top: 0.5rem;
  color: #db0400;
  font-size: 0.625rem;
}
.header__sp-navigation {
  display: none;
}
@media screen and (max-width: 960px) {
  .header__sp-navigation {
    display: block;
    position: absolute;
    z-index: 100;
    top: 3.5rem;
    left: 0;
    width: 100vw;
    height: calc(100vh - 3.5rem);
    /* stylelint-disable */
    padding: 3.5rem 5% 1.5rem;
    padding: 3.5rem 5% calc(100vh - 100svh + 1.5rem);
    /* stylelint-enable */
    overflow: scroll;
    transition: all 300ms;
    opacity: 0;
    background-color: #f5f5f5;
    pointer-events: none;
    overscroll-behavior: contain;
  }
}
.header__sp-navigation.active {
  opacity: 1;
  pointer-events: all;
}
.header__sp-navigation > ul {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
}
.header__sp-navigation > ul li.services ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
  margin-top: 1.5rem;
  padding-left: 1.5rem;
}
.header__sp-navigation > ul li.services ul li {
  display: flex;
  align-items: center;
}
.header__sp-navigation > ul li.services ul li::before {
  content: "";
  display: block;
  width: 1rem;
  height: 1px;
  margin-right: 0.5rem;
  background-color: #db0400;
}
.header__sp-navigation > ul li.services ul a {
  font-size: 1rem;
}
.header__sp-navigation > ul a {
  color: #000;
  font-size: 1.25rem;
}
.header__sp-navigation__tel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  padding: 1.5rem;
  border: solid 2px #db0400;
  background-color: #fff;
}
.header__sp-navigation__tel h2 {
  width: fit-content;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  border-radius: 100vh;
  background-color: #db0400;
  color: #fff;
}
.header__sp-navigation__tel .sp-tel-info {
  margin-top: 1.125rem;
  text-align: center;
}
.header__sp-navigation__tel .sp-tel-info .tel-number {
  margin-top: 0.5rem;
}
.header__sp-navigation__tel .sp-tel-info .tel-number a {
  color: #000;
  font-size: 2.5rem;
}
.header__sp-navigation__tel .sp-tel-info .business-hour {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}

.footer.container {
  padding: 5rem 5rem 1.5rem;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .footer.container {
    padding: 0 0 0.5rem;
  }
}
.footer .content {
  position: relative;
}
.footer__main-wrapper {
  display: flex;
  gap: 8.75rem;
}
@media screen and (max-width: 960px) {
  .footer__main-wrapper {
    flex-direction: column-reverse;
    gap: unset;
  }
}
.footer__logo {
  width: 16.25rem;
}
@media screen and (max-width: 960px) {
  .footer__logo {
    width: 8.75rem;
    margin: 2.5rem auto 0;
  }
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation {
    padding: 2.5rem 5% 1.5rem;
  }
}
.footer__menu__navigation ul.list > li a {
  display: block;
  transition: all 300ms;
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li a {
    font-size: 1rem;
  }
}
.footer__menu__navigation ul.list > li a:hover {
  opacity: 0.6;
}
.footer__menu__navigation ul.list > li:nth-child(2) {
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li:nth-child(2) {
    margin-top: 1.5rem;
  }
}
.footer__menu__navigation ul.list > li:nth-child(2) > ul {
  display: flex;
  gap: 1.5rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li:nth-child(2) > ul {
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li:nth-child(2) > ul > li {
    position: relative;
    padding-left: 1.25rem;
  }
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li:nth-child(2) > ul > li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 0.5rem;
    height: 2px;
    background-color: #000;
  }
}
.footer__menu__navigation ul.list > li:nth-child(2) > ul > li a {
  display: inline-block;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}
.footer__menu__navigation ul.list > li:nth-child(3) {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li:nth-child(3) {
    flex-direction: column;
  }
}
.footer__menu__navigation ul.list > li:nth-child(3) > ul {
  display: flex;
  gap: 1.5rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__navigation ul.list > li:nth-child(3) > ul {
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .footer__menu__access {
    padding: 1.5rem 5%;
    background-color: #f5f5f5;
  }
}
.footer__menu__access .map {
  display: flex;
  align-items: center;
  gap: 0 0.875rem;
  margin-top: 2rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__access .map {
    flex-direction: column;
    align-items: unset;
    margin-top: 0;
  }
}
@media screen and (max-width: 960px) {
  .footer__menu__access .map p {
    line-height: 1.2;
  }
}
.footer__menu__access .map a {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1.25rem;
  transition: all 300ms;
  border-radius: 100vh;
  background-color: #db0400;
  gap: 0.25rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__access .map a {
    margin-top: 0.5rem;
    padding: 0.375rem 0.75rem;
  }
}
.footer__menu__access .map a:hover {
  opacity: 0.6;
}
.footer__menu__access .map a .icon {
  width: 0.729rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__access .map a .icon {
    width: 0.688rem;
  }
}
.footer__menu__access .map a p {
  font-size: 0.875rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .footer__menu__access .map a p {
    font-size: 0.75rem;
  }
}
.footer__menu__access .tel {
  margin-top: 1rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .footer__menu__access .tel {
    font-size: 1rem;
  }
}
.footer__menu__access .tel a {
  color: #000;
  pointer-events: none;
}
@media screen and (max-width: 960px) {
  .footer__menu__access .tel a {
    pointer-events: all;
  }
}
.footer__copyright {
  margin-top: 6.25rem;
  font-size: 0.875rem;
}
@media screen and (max-width: 960px) {
  .footer__copyright {
    margin-top: 2.5rem;
    font-size: 0.625rem;
    text-align: center;
  }
}
.footer .scroll-button {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  transition: all 300ms;
  border: solid 1px #db0400;
  border-radius: 100vh;
}
@media screen and (max-width: 960px) {
  .footer .scroll-button {
    right: 5%;
    width: 3rem;
    height: 3rem;
  }
}
.footer .scroll-button:hover {
  opacity: 0.6;
}
.footer .scroll-button__arrow {
  width: 1rem;
}

.common-works {
  position: relative;
}
.common-works__background-image {
  width: 100%;
  max-height: 540px;
  overflow: hidden;
}
@media screen and (min-width: 1440px) {
  .common-works__background-image {
    background-image: url("../images/common/common_works-link-image.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .common-works__background-image img {
    visibility: hidden;
  }
}
.common-works__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 3.5rem 5rem 2.5rem;
}
@media screen and (max-width: 960px) {
  .common-works__content {
    padding: 1rem 5% 1.5rem;
  }
}
.common-works__content__heading p.decoration-text {
  position: relative;
  z-index: 1;
  color: #fff;
  font-family: "Lora", serif;
  font-size: 8.75rem;
}
@media screen and (max-width: 960px) {
  .common-works__content__heading p.decoration-text {
    font-size: 4.5rem;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  }
}
.common-works__content__heading h2 {
  position: relative;
  z-index: 2;
  margin-top: -1.625rem;
  font-size: 1.625rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .common-works__content__heading h2 {
    margin-top: -1.25rem;
    font-size: 1.25rem;
  }
}
.common-works__content__heading p.detail-text {
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .common-works__content__heading p.detail-text {
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.2;
  }
}
.common-works__content__link {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  position: absolute;
  right: 5rem;
  bottom: 2.5rem;
  width: fit-content;
  padding: 0.5rem 1.5rem 0.5rem 2.25rem;
  background-color: #db0400;
}
.common-works__content__link:hover p::after {
  animation-name: link-hover;
}
@media screen and (max-width: 960px) {
  .common-works__content__link {
    right: 1.125rem;
    bottom: 1.5rem;
    gap: 0 0.5rem;
    padding: 0.25rem 0.75rem 0.25rem 1rem;
  }
}
.common-works__content__link p {
  position: relative;
  color: #fff;
}
.common-works__content__link p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 960px) {
  .common-works__content__link p {
    font-size: 0.875rem;
  }
}
.common-works__content__link .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  border-radius: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .common-works__content__link .arrow {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
  }
}

.common-company {
  background-color: #fff;
}
.common-company.container {
  padding: 3.5rem 5rem 2.5rem;
}
@media screen and (max-width: 960px) {
  .common-company.container {
    padding: 1.5rem 5% 2.5rem;
  }
}
.common-company .decoration-text {
  color: #f5f5f5;
  font-family: "Shippori Mincho", serif;
  font-size: 8.75rem;
}
@media screen and (max-width: 960px) {
  .common-company .decoration-text {
    margin-left: -5vw;
    font-size: 4.5rem;
  }
}
.common-company h2 {
  position: relative;
  z-index: 2;
  margin-top: -1.625rem;
  font-size: 1.625rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .common-company h2 {
    margin-top: -1.25rem;
    font-size: 1.25rem;
  }
}
.common-company .detail-text {
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .common-company .detail-text {
    margin-top: 1.5rem;
    line-height: 1.6;
  }
}
.common-company__content-link {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  width: fit-content;
  margin-top: 5rem;
  margin-left: auto;
}
@media screen and (max-width: 960px) {
  .common-company__content-link {
    gap: 0 0.5rem;
    margin-top: 1rem;
  }
}
.common-company__content-link:hover p::after {
  width: 100%;
  animation-name: link-hover;
}
.common-company__content-link p {
  position: relative;
  color: #000;
}
@media screen and (max-width: 960px) {
  .common-company__content-link p {
    font-size: 0.875rem;
  }
}
.common-company__content-link p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  background-color: #000;
}
.common-company__content-link .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  border: solid 1px #db0400;
  border-radius: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .common-company__content-link .arrow {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
  }
}

.common-recruit.container {
  padding: 2.5rem 5rem;
  background-color: #db0400;
}
@media screen and (max-width: 960px) {
  .common-recruit.container {
    padding: 2.5rem 5%;
  }
}
.common-recruit .content {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .common-recruit .content {
    flex-direction: column-reverse;
  }
}
.common-recruit .content .decoration-text {
  color: #f5f5f5;
  font-family: "Shippori Mincho", serif;
  font-size: 8.75rem;
}
@media screen and (max-width: 960px) {
  .common-recruit .content .decoration-text {
    font-size: 3rem;
  }
}
.common-recruit__content {
  width: 48%;
}
@media screen and (max-width: 960px) {
  .common-recruit__content {
    width: 100%;
  }
}
.common-recruit__content h2 {
  margin-top: 1rem;
  color: #fff;
  font-size: 2.25rem;
  line-height: 1.5;
}
@media screen and (max-width: 960px) {
  .common-recruit__content h2 {
    margin-top: 0.5rem;
    font-size: 1.75rem;
    line-height: 1.4;
  }
}
.common-recruit__content .message-text {
  margin-top: 1rem;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.6;
}
@media screen and (max-width: 960px) {
  .common-recruit__content .message-text {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}
.common-recruit__content__link {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  width: fit-content;
  margin-top: 2.5rem;
  margin-left: auto;
}
@media screen and (max-width: 960px) {
  .common-recruit__content__link {
    gap: 0 0.5rem;
    margin-top: 1rem;
  }
}
.common-recruit__content__link:hover p::after {
  width: 100%;
  animation-name: link-hover;
}
.common-recruit__content__link p {
  position: relative;
  color: #fff;
}
@media screen and (max-width: 960px) {
  .common-recruit__content__link p {
    font-size: 0.875rem;
  }
}
.common-recruit__content__link p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  background-color: #fff;
}
.common-recruit__content__link .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  border: solid 1px #db0400;
  border-radius: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .common-recruit__content__link .arrow {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
  }
}
.common-recruit__image {
  width: 45%;
}
@media screen and (max-width: 960px) {
  .common-recruit__image {
    width: 100%;
    margin-top: 1rem;
  }
}

.breadcrumb {
  margin-top: 2.5rem;
}
.breadcrumb ul {
  display: flex;
  align-items: center;
  gap: 0 1rem;
}
@media screen and (max-width: 960px) {
  .breadcrumb ul {
    gap: 0 0.5rem;
  }
}
@media screen and (max-width: 960px) {
  .breadcrumb ul > li {
    font-size: 0.75rem;
  }
}
.breadcrumb ul > li:not(:first-of-type) {
  display: flex;
  align-items: center;
}
.breadcrumb ul > li:not(:first-of-type)::before {
  content: "";
  display: block;
  width: 1px;
  height: 0.75rem;
  margin-right: 1rem;
  background-color: #000;
}
@media screen and (max-width: 960px) {
  .breadcrumb ul > li:not(:first-of-type)::before {
    margin-right: 0.5rem;
  }
}
.breadcrumb ul > li > a {
  color: #b1b1b1;
  transition: all 300ms;
}
.breadcrumb ul > li > a:hover {
  color: #000;
}

.page-hero {
  max-width: unset !important;
  margin-top: 5rem;
  padding-right: unset !important;
  padding-left: unset !important;
}
@media screen and (max-width: 960px) {
  .page-hero {
    margin-top: 1.5rem;
  }
}
.page-hero__heading h1 {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 5rem;
  font-size: 3.5rem;
}
@media screen and (max-width: 960px) {
  .page-hero__heading h1 {
    padding: 0 5%;
    font-size: 2rem;
  }
}
.page-hero__heading .eng-sub {
  max-width: 1440px;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0 5rem;
  font-size: 1.5rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 960px) {
  .page-hero__heading .eng-sub {
    margin-top: 0.75rem;
    padding: 0 5%;
    font-size: 1.0625rem;
  }
}
.page-hero__heading .eng-sub::first-letter {
  color: #db0400;
}
.page-hero__main-visual {
  width: 100%;
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .page-hero__main-visual {
    margin-top: 2.5rem;
  }
}
.page-hero__main-visual[style="background-image: url(..//);"] {
  display: none;
}
@media screen and (min-width: 1440px) {
  .page-hero__main-visual {
    height: 540px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .page-hero__main-visual img {
    display: none;
  }
}

.common-worry.container {
  max-width: unset;
  padding: 3.5rem 5rem;
  background-color: #fff3ef;
}
@media screen and (max-width: 960px) {
  .common-worry.container {
    padding: 2.5rem 5%;
  }
}
.common-worry .content {
  max-width: 1260px;
  margin: 0 auto;
}
.common-worry h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .common-worry h2 {
    font-size: 1rem;
  }
}
.common-worry h2 span {
  display: inline-block;
  padding: 0 0.25rem;
  color: #db0400;
  font-size: 2.5rem;
}
@media screen and (max-width: 960px) {
  .common-worry h2 span {
    font-size: 1.75rem;
  }
}
.common-worry__main-content {
  margin-top: 2rem;
  padding: 3rem 3rem 3.75rem;
  border: solid 2px #db0400;
  border-radius: 1rem;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .common-worry__main-content {
    margin-top: 1.5rem;
    padding: 1.5rem 1.5rem 3rem;
    border-radius: 0.5rem;
  }
}
.common-worry__main-content__list {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
@media screen and (max-width: 960px) {
  .common-worry__main-content__list {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }
}
.common-worry__main-content__list li {
  display: table-cell;
  width: 25%;
  padding: 1rem 0;
  border-right: dashed 2px #db0400;
  border-left: dashed 2px #db0400;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .common-worry__main-content__list li {
    width: 100%;
    padding: unset;
    border-right: unset;
    border-left: unset;
  }
  .common-worry__main-content__list li:not(:first-of-type) {
    padding-top: 1rem;
    border-top: dashed 2px #db0400;
  }
}
.common-worry__main-content__list li span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 auto;
  border-radius: 100vh;
  background-color: #db0400;
  color: #fff;
  font-size: 0.875rem;
}
@media screen and (max-width: 960px) {
  .common-worry__main-content__list li span {
    width: 1.5rem;
    height: 1.5rem;
    border: solid 1px #db0400;
    background-color: #fff;
    color: #db0400;
    font-size: 0.75rem;
  }
}
.common-worry__main-content__list li p {
  margin-top: 0.75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (max-width: 960px) {
  .common-worry__main-content__list li p {
    margin-top: 0.5rem;
    font-feature-settings: "palt";
  }
}
.common-worry__main-content .and-more {
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: right;
}
@media screen and (max-width: 960px) {
  .common-worry__main-content .and-more {
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
  }
}
.common-worry__illust {
  width: 47%;
  margin: -5rem auto 0;
}
@media screen and (max-width: 960px) {
  .common-worry__illust {
    width: 90%;
    margin: -2rem auto 0;
  }
}
.common-worry__link {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  width: fit-content;
  margin: 2rem auto 0;
  padding: 0.5rem 1.5rem;
  background-color: #db0400;
}
@media screen and (max-width: 960px) {
  .common-worry__link {
    margin-top: 1.5rem;
  }
}
.common-worry__link:hover p::after {
  animation-name: link-hover;
}
.common-worry__link p {
  position: relative;
  color: #fff;
  font-weight: 500;
}
@media screen and (max-width: 960px) {
  .common-worry__link p {
    font-size: 0.875rem;
  }
}
.common-worry__link p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  background-color: #fff;
}
.common-worry__link .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  border-radius: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .common-worry__link .arrow {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
  }
}

.services-normal .services-main-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 0 5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-main-wrapper {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.services-normal .in-page-navigation {
  position: sticky;
  top: 2.5rem;
  flex-shrink: 0;
  width: 28.5%;
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .services-normal .in-page-navigation {
    display: none;
  }
}
.services-normal .in-page-navigation > li {
  border-bottom: solid 1px #db0400;
}
.services-normal .in-page-navigation > li:first-child {
  border-top: solid 1px #db0400;
}
.services-normal .in-page-navigation > li > p {
  padding: 1rem;
  background-color: #db0400;
  color: #fff;
}
.services-normal .in-page-navigation > li > a {
  display: block;
  padding: 1rem;
  transition: all 300ms;
  color: #000;
}
.services-normal .in-page-navigation > li > a:hover {
  background-color: #db0400;
  color: #fff;
}
.services-normal .in-page-navigation > li .sub-navigation,
.services-normal .in-page-navigation > li .sub-navigation-child {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem 0;
}
.services-normal .in-page-navigation > li .sub-navigation > li,
.services-normal .in-page-navigation > li .sub-navigation-child > li {
  position: relative;
  padding-left: 1.75rem;
}
.services-normal .in-page-navigation > li .sub-navigation > li > a,
.services-normal .in-page-navigation > li .sub-navigation-child > li > a {
  display: inline-block;
  position: relative;
  transition: all 300ms;
  color: #000;
}
.services-normal .in-page-navigation > li .sub-navigation > li > a:hover,
.services-normal .in-page-navigation > li .sub-navigation-child > li > a:hover {
  color: #db0400;
}
.services-normal .in-page-navigation > li .sub-navigation {
  padding-bottom: 1rem;
}
.services-normal .in-page-navigation > li .sub-navigation > li > a:hover::before {
  width: 1rem;
}
.services-normal .in-page-navigation > li .sub-navigation > li > a.active {
  color: #db0400;
}
.services-normal .in-page-navigation > li .sub-navigation > li > a.active::before {
  width: 1rem;
}
.services-normal .in-page-navigation > li .sub-navigation > li > a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -1.75rem;
  width: 0;
  height: 2px;
  transform: translateY(-50%);
  transition: all 300ms;
  background-color: #db0400;
}
.services-normal .in-page-navigation > li .sub-navigation-child > li {
  padding-left: 1em;
}
.services-normal .in-page-navigation > li .sub-navigation-child > li > a {
  color: #000;
}
.services-normal .in-page-navigation > li .sub-navigation-child > li > a::before {
  left: calc(-1.75rem - 1em);
}
.services-normal .main-content {
  width: 100%;
}
.services-normal .main-content h2.normal-heading {
  padding-bottom: 1.5rem;
  border-bottom: solid 4px #db0400;
  color: #db0400;
  font-size: 2rem;
}
@media screen and (max-width: 960px) {
  .services-normal .main-content h2.normal-heading {
    padding-bottom: 1rem;
    font-size: 1.5rem;
  }
}
.services-normal .main-content__service-image {
  margin-top: 1.5rem;
}
.services-normal .main-content__explain {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.6;
}
@media screen and (max-width: 960px) {
  .services-normal .main-content__explain {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}
.services-normal .main-content h3.sub-heading {
  margin-top: 2rem;
  padding-left: 0.5rem;
  border-left: solid 5px #db0400;
  font-size: 1.5rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .services-normal .main-content h3.sub-heading {
    font-size: 1.125rem;
  }
}
.services-normal .relation-link {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .services-normal .relation-link {
    margin-top: 2.5rem;
  }
}
.services-normal .relation-link > p {
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .services-normal .relation-link > p {
    font-size: 1rem;
  }
}
.services-normal .relation-link a {
  display: flex;
  position: relative;
  width: fit-content;
  margin: 1rem auto 0;
  padding: 1.5rem 3.5rem;
  transition: all 300ms;
  background-color: #db0400;
}
@media screen and (max-width: 960px) {
  .services-normal .relation-link a {
    padding: 1rem 2.5rem;
  }
}
.services-normal .relation-link a:hover {
  opacity: 0.6;
}
.services-normal .relation-link a p {
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .services-normal .relation-link a p {
    font-size: 1.125rem;
  }
}
.services-normal .relation-link a .arrow {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 0.625rem;
  height: auto;
  transform: translateY(-50%);
}
@media screen and (max-width: 960px) {
  .services-normal .relation-link a .arrow {
    width: 0.5rem;
  }
}
.services-normal .services-bottom-link {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link {
    margin-top: 3.5rem;
  }
}
.services-normal .services-bottom-link__works {
  display: block;
  position: relative;
  overflow: hidden;
}
.services-normal .services-bottom-link__works:hover .image {
  transform: scale(1.1);
}
.services-normal .services-bottom-link__works .image {
  position: relative;
  transition: all 300ms;
}
.services-normal .services-bottom-link__works .image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.services-normal .services-bottom-link__works .link-content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.services-normal .services-bottom-link__works .link-content > p {
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__works .link-content > p {
    font-size: 1rem;
  }
}
.services-normal .services-bottom-link__works .link-content .button {
  position: relative;
  margin-top: 2rem;
  padding: 1.125rem 5rem;
  border: solid 1px #fff;
  font-size: 1.25rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__works .link-content .button {
    margin-top: 1.5rem;
    font-size: 1.125rem;
  }
}
.services-normal .services-bottom-link__works .link-content .button span {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 0.5rem;
  transform: translateY(-50%);
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__works .link-content .button span {
    right: 0.75rem;
    width: 0.375rem;
  }
}
.services-normal .services-bottom-link__tel-wrapper {
  margin-top: 1.5rem;
  padding: 2.5rem;
  border: solid 2px #00b292;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper {
    margin-top: 1rem;
    padding: 1.5rem;
  }
}
.services-normal .services-bottom-link__tel-wrapper h2 {
  font-size: 1.25rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper h2 {
    font-size: 1.125rem;
    line-height: 1.4;
  }
}
.services-normal .services-bottom-link__tel-wrapper .tel-wrapper {
  width: fit-content;
  margin: 1.5rem auto 0;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper .tel-wrapper {
    margin-top: 1rem;
  }
}
.services-normal .services-bottom-link__tel-wrapper .tel-wrapper .tel-number {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper .tel-wrapper .tel-number {
    flex-direction: column;
  }
}
.services-normal .services-bottom-link__tel-wrapper .tel-wrapper .tel-number p {
  transform: translateY(15%);
  font-size: 2rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper .tel-wrapper .tel-number p {
    transform: unset;
    font-size: 1rem;
  }
}
.services-normal .services-bottom-link__tel-wrapper .tel-wrapper .tel-number a {
  display: inline-block;
  color: #000;
  font-size: 4.5rem;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper .tel-wrapper .tel-number a {
    margin-top: 0.5rem;
    font-size: 2.5rem;
  }
}
.services-normal .services-bottom-link__tel-wrapper .tel-wrapper .business-hour {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.25rem;
  background-color: #00b292;
  color: #fff;
  font-size: 1.25rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .services-normal .services-bottom-link__tel-wrapper .tel-wrapper .business-hour {
    margin-top: 0.25rem;
    font-size: 0.75rem;
  }
}

html {
  font-size: 16px;
  font-size: 1.25vw;
}
@media screen and (max-width: 960px) {
  html {
    font-size: 16px;
    font-size: 4.2666666667vw;
  }
}
@media screen and (max-width: 375px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1440px) {
  html {
    font-size: 18px;
  }
}

body {
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  /* stylelint-disable-line */
  text-align: justify;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

a {
  color: #fff;
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5rem;
  padding-left: 5rem;
}
@media screen and (max-width: 960px) {
  .container {
    padding: 0 5%;
  }
}
.container--full-width {
  max-width: unset;
  padding: 0;
}
@media screen and (max-width: 960px) {
  .container--full-width {
    padding: 0;
  }
}

.no-pc {
  display: none;
}
@media screen and (max-width: 960px) {
  .no-pc {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .no-sp {
    display: none;
  }
}

.header.header--top {
  position: absolute;
}
@media screen and (max-width: 960px) {
  .header.header--top {
    position: sticky;
  }
}

.top .fv {
  font-family: "Shippori Mincho", serif;
}
.top .fv content {
  position: relative;
}
.top .fv__slider {
  /* stylelint-disable */
  /* stylelint-enable */
}
.top .fv__slider .splide__slide {
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: bottom;
  background-size: cover;
}
@media screen and (max-width: 960px) {
  .top .fv__slider .splide__slide {
    height: 18.75rem;
  }
}
.top .fv__slider .splide__slide--01 {
  background-image: url("../images/top/fv_slider-01.webp");
}
@media screen and (max-width: 960px) {
  .top .fv__slider .splide__slide--01 {
    background-image: url("../images/top/fv_slider-01_sp.webp");
  }
}
.top .fv__slider .splide__slide--02 {
  background-image: url("../images/top/fv_slider-02.webp");
}
@media screen and (max-width: 960px) {
  .top .fv__slider .splide__slide--02 {
    background-image: url("../images/top/fv_slider-02_sp.webp");
  }
}
.top .fv__slider .splide__slide--03 {
  background-image: url("../images/top/fv_slider-03.webp");
}
@media screen and (max-width: 960px) {
  .top .fv__slider .splide__slide--03 {
    background-image: url("../images/top/fv_slider-03_sp.webp");
  }
}
.top .fv__slider .splide__slide--04 {
  background-image: url("../images/top/fv_slider-04.webp");
}
@media screen and (max-width: 960px) {
  .top .fv__slider .splide__slide--04 {
    background-image: url("../images/top/fv_slider-04_sp.webp");
  }
}
.top .fv__slider .splide__slide--05 {
  background-image: url("../images/top/fv_slider-05.webp");
}
@media screen and (max-width: 960px) {
  .top .fv__slider .splide__slide--05 {
    background-image: url("../images/top/fv_slider-05_sp.webp");
  }
}
.top .fv__pagination {
  display: flex;
  gap: 1.5rem;
  position: absolute;
  right: 2.5rem;
  bottom: 2.688rem;
}
@media screen and (max-width: 960px) {
  .top .fv__pagination {
    gap: 1.25rem;
    right: unset;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
.top .fv__pagination li {
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100vh;
  background-color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 960px) {
  .top .fv__pagination li {
    width: 0.625rem;
    height: 0.625rem;
  }
}
.top .fv__canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.75rem;
  height: 1.75rem;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.top .fv__message {
  position: absolute;
  top: 22.5vh;
  left: 15%;
}
@media screen and (max-width: 960px) {
  .top .fv__message {
    position: unset;
    padding: 3rem 5%;
    background-color: transparent;
  }
}
.top .fv__message h2 {
  font-size: 8.25vh;
}
@media screen and (max-width: 960px) {
  .top .fv__message h2 {
    font-size: 2rem;
  }
}
.top .fv__message .text {
  margin-top: calc(7vh - 0.7em);
  margin-bottom: -0.7em;
  font-size: 2.25vh;
  line-height: 2.4;
}
@media screen and (max-width: 960px) {
  .top .fv__message .text {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.6;
  }
}
.top .fv__message h1 {
  margin-top: calc(7vh - 0.75em);
  font-size: 4.5vh;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .top .fv__message h1 {
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }
}
.top .fv__scroll-down {
  position: absolute;
  bottom: 0;
  left: 11.813rem;
  outline: none;
  color: #fff;
  font-size: 2.25vh;
}
@media screen and (max-width: 960px) {
  .top .fv__scroll-down {
    display: block;
    position: unset;
    width: fit-content;
    margin: 0 auto;
    color: #db0400;
    font-size: 1rem;
  }
}
.top .fv__scroll-down span {
  display: block;
  width: 1px;
  height: 7.5vh;
  margin-top: 1vh;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .top .fv__scroll-down span {
    height: 2.5rem;
    background-color: #db0400;
  }
}
.top .message {
  position: relative;
  z-index: 1;
  background-color: transparent;
  font-family: "Shippori Mincho", serif;
}
.top .message.container {
  max-width: unset;
}
.top .message .content {
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
}
.top .message__body {
  position: absolute;
  left: 28.063rem;
}
@media screen and (max-width: 960px) {
  .top .message__body {
    position: unset;
  }
}
.top .message__title {
  position: relative;
  padding-top: 16.375rem;
}
@media screen and (max-width: 960px) {
  .top .message__title {
    margin-top: 5.125rem;
    padding: unset;
    padding-top: unset;
  }
}
.top .message__title p {
  position: absolute;
  top: 9.5rem;
  color: #fff;
  font-family: "Lora", serif;
  font-size: 8.75rem;
}
@media screen and (max-width: 960px) {
  .top .message__title p {
    z-index: -1;
    top: -3rem;
    right: -5vw;
    font-size: 4.5rem;
  }
}
.top .message__title h2 {
  font-size: 2rem;
  line-height: 1.5;
}
@media screen and (max-width: 960px) {
  .top .message__title h2 {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}
.top .message__text {
  margin-top: calc(2.5rem - 0.25em - 0.5em);
  font-size: 1.125rem;
  line-height: 2;
}
@media screen and (max-width: 960px) {
  .top .message__text {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}
.top .message__image {
  width: 48.438rem;
  margin-left: -11.563rem;
}
@media screen and (max-width: 960px) {
  .top .message__image {
    width: 100%;
    margin-top: 1rem;
    margin-left: unset;
  }
}
.top .reason {
  margin-top: 0;
  font-family: "Shippori Mincho", serif;
}
@media screen and (max-width: 960px) {
  .top .reason {
    margin-top: 3.5rem;
  }
}
.top .reason__title {
  position: relative;
  padding-top: 4.313rem;
}
@media screen and (max-width: 960px) {
  .top .reason__title {
    padding: unset;
  }
}
.top .reason__title p {
  position: absolute;
  z-index: 1;
  top: -2.625rem;
  color: #fff;
  font-family: "Lora", serif;
  font-size: 8.75rem;
}
@media screen and (max-width: 960px) {
  .top .reason__title p {
    margin-left: -5vw;
    font-size: 4.5rem;
  }
}
.top .reason__title h2 {
  position: relative;
  z-index: 1;
  font-size: 1.625rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .top .reason__title h2 {
    font-size: 1.75rem;
  }
}
.top .reason__logo {
  position: absolute;
  right: -2.5rem;
  bottom: -9.125rem;
  width: 34rem;
}
.top .reason__media {
  display: flex;
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .top .reason__media {
    flex-direction: column;
    margin-top: 2rem;
  }
}
.top .reason__media--even {
  flex-direction: row-reverse;
}
@media screen and (max-width: 960px) {
  .top .reason__media--even {
    flex-direction: column;
  }
}
.top .reason__label {
  margin-top: 2.5rem;
  margin-right: 2.5rem;
  color: #db0400;
  font-family: "Lora", serif;
  font-size: 1.625rem;
  writing-mode: vertical-rl;
}
@media screen and (max-width: 960px) {
  .top .reason__label {
    writing-mode: unset;
    margin-top: unset;
    margin-right: unset;
    font-size: 1.25rem;
  }
}
.top .reason__label--even {
  margin-right: unset;
  margin-left: 2.5rem;
}
@media screen and (max-width: 960px) {
  .top .reason__label--even {
    margin-left: unset;
  }
}
.top .reason__body {
  flex-shrink: 0;
  margin-top: calc(2.5rem - 0.25em);
  margin-right: 5.875rem;
}
@media screen and (max-width: 960px) {
  .top .reason__body {
    flex-shrink: unset;
    margin-top: 0;
    margin-right: 0;
  }
}
.top .reason__body--even {
  margin-right: unset;
  margin-left: 3.375rem;
}
@media screen and (max-width: 960px) {
  .top .reason__body--even {
    margin-left: unset;
  }
}
.top .reason__body h3 {
  font-size: 2.5rem;
  line-height: 1.5;
}
@media screen and (max-width: 960px) {
  .top .reason__body h3 {
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }
}
.top .reason__body p {
  margin-top: 0.5rem;
  line-height: 2;
}
@media screen and (max-width: 960px) {
  .top .reason__body p {
    margin-top: 0.75rem;
  }
}
.top .reason__image {
  position: relative;
}
@media screen and (max-width: 960px) {
  .top .reason__image {
    margin-top: 0.5rem;
  }
}
.top .service {
  margin-top: 4.625rem;
  padding-top: 4.625rem;
  padding-bottom: 5rem;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .top .service {
    margin-top: 3.5rem;
    padding-top: 3.5rem;
  }
}
.top .service.container {
  max-width: unset;
}
.top .service .content {
  max-width: 1260px;
  margin: 0 auto;
}
.top .service .decoration-text {
  position: relative;
  z-index: 1;
  color: #f5f5f5;
  font-family: Lora, serif;
  font-size: 8.75rem;
}
@media screen and (max-width: 960px) {
  .top .service .decoration-text {
    margin-left: -5vw;
    font-size: 4.5rem;
  }
}
.top .service h2 {
  position: relative;
  z-index: 2;
  margin-top: -1.625rem;
  font-size: 1.625rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .top .service h2 {
    margin-top: -1.25rem;
    font-size: 1.75rem;
  }
}
.top .service__works {
  display: flex;
  margin-top: 5rem;
  gap: 2.5rem;
}
@media screen and (max-width: 960px) {
  .top .service__works {
    flex-direction: column;
    margin-top: 2rem;
    gap: 2rem;
  }
}
.top .service__item {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.top .service__item h3 {
  font-size: 2rem;
}
@media screen and (max-width: 960px) {
  .top .service__item h3 {
    order: 2;
    margin-top: 1rem;
    font-size: 1.25rem;
  }
}
.top .service__item p {
  flex-grow: 1;
  margin-top: calc(1rem - 0.3em);
  line-height: 1.6;
}
@media screen and (max-width: 960px) {
  .top .service__item p {
    order: 3;
    margin-top: 0.5rem;
  }
}
.top .service__image {
  margin-top: 1.5rem;
}
@media screen and (max-width: 960px) {
  .top .service__image {
    order: 1;
    margin-top: unset;
  }
}
.top .service__content-link {
  display: flex;
  align-items: baseline;
  width: fit-content;
  margin-top: 2.5rem;
  margin-left: auto;
  gap: 0 1rem;
}
@media screen and (max-width: 960px) {
  .top .service__content-link {
    flex-direction: row-reverse;
    order: 4;
    margin-top: 0.5rem;
    gap: 0 0.5rem;
  }
}
.top .service__content-link:hover p::after {
  width: 100%;
  animation-name: link-hover;
}
.top .service__content-link p {
  position: relative;
  margin-top: 0;
  color: #000;
}
@media screen and (max-width: 960px) {
  .top .service__content-link p {
    margin-top: 0;
    font-size: 0.875rem;
  }
}
.top .service__content-link p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  background-color: #000;
}
.top .service__content-link .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  border: solid 1px #db0400;
  border-radius: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .top .service__content-link .arrow {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
  }
}
.top .background-map {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #edf0ef;
}

.services .about .content {
  position: relative;
  padding: 7.5rem 0;
}
@media screen and (max-width: 960px) {
  .services .about .content {
    padding: 3.5rem 0 0;
  }
}
.services .about__decoration-text {
  position: absolute;
  top: 0;
  left: -5rem;
  transform: translateY(-50%);
  color: #db0400;
  font-family: "Lora", serif;
  font-size: 7.5rem;
}
@media screen and (max-width: 960px) {
  .services .about__decoration-text {
    left: -5vw;
    font-size: 4rem;
  }
}
.services .about__main-wrapper__text-wrapper {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .services .about__main-wrapper__text-wrapper {
    width: 100%;
  }
}
.services .about__main-wrapper__text-wrapper h2 {
  font-size: 2rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .services .about__main-wrapper__text-wrapper h2 {
    font-size: 1.625rem;
  }
}
.services .about__main-wrapper__text-wrapper p {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}
@media screen and (max-width: 960px) {
  .services .about__main-wrapper__text-wrapper p {
    font-size: 1rem;
  }
}
.services .about__main-wrapper__image-wrapper {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 960px) {
  .services .about__main-wrapper__image-wrapper {
    position: static;
    width: 100vw;
    margin-top: 2.5rem;
    margin-left: -5vw;
  }
}
.services .about__main-wrapper__image-wrapper img {
  position: absolute;
  right: -5rem;
  width: auto;
  height: 100%;
}
@media screen and (max-width: 960px) {
  .services .about__main-wrapper__image-wrapper img {
    position: static;
    right: unset;
    width: 100%;
    height: auto;
  }
}
.services .service-link {
  max-width: unset;
  background-color: #edf0ef;
}
.services .service-link .content {
  max-width: 1260px;
  margin: 0 auto;
  padding: 5rem 0;
}
@media screen and (max-width: 960px) {
  .services .service-link .content {
    padding: 3.5rem 0;
  }
}
.services .service-link__link {
  display: flex;
  flex-direction: column;
  gap: 2.5rem 0;
}
@media screen and (max-width: 960px) {
  .services .service-link__link {
    gap: 2rem 0;
  }
}
.services .service-link__link li {
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li {
    padding: 1.5rem 5vw;
    background-color: #db0400;
  }
}
.services .service-link__link li a:hover .image {
  transform: scale(1.1);
}
@media screen and (max-width: 960px) {
  .services .service-link__link li a:hover .image {
    transform: unset;
  }
}
.services .service-link__link li h2 {
  color: #fff;
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li h2 {
    display: flex;
    align-items: center;
  }
  .services .service-link__link li h2 span {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
  }
  .services .service-link__link li h2 span::after {
    content: "";
    display: block;
    width: 1px;
    height: 2rem;
    margin-left: 0.75rem;
    background-color: #fff;
  }
}
.services .service-link__link li .image {
  position: relative;
  transition: all 300ms;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li .image {
    margin-top: 1rem;
  }
}
.services .service-link__link li .image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  mix-blend-mode: multiply;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li .image::before {
    display: none;
  }
}
.services .service-link__link li .service-details {
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  align-items: center;
  width: 49%;
  padding: 2.5rem;
  transform: translateY(-50%);
  background-color: #db0400;
  gap: 0 1.5rem;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li .service-details {
    position: static;
    width: 100%;
    margin-top: 1rem;
    padding: unset;
    transform: unset;
    background-color: unset;
  }
}
.services .service-link__link li .service-details .number {
  font-family: "Shippori Mincho", serif;
  font-size: 4rem;
}
.services .service-link__link li .service-details .text-wrapper .heading {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li .service-details .text-wrapper .heading {
    display: none;
  }
}
.services .service-link__link li .service-details .text-wrapper .heading .arrow {
  width: 1.25rem;
}
.services .service-link__link li .service-details .text-wrapper p {
  margin-top: 1rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .services .service-link__link li .service-details .text-wrapper p {
    margin-top: unset;
  }
}
@media screen and (max-width: 960px) {
  .services .service-link__link li .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fff;
  }
  .services .service-link__link li .button p {
    color: #db0400;
  }
  .services .service-link__link li .button .arrow {
    width: 1rem;
  }
}
.services .common-worry {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .services .common-worry {
    margin-top: 3.5rem;
  }
}

.service-solutions .worries {
  max-width: unset;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #fff3ef;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.service-solutions .worries .content {
  max-width: 1260px;
  margin: 0 auto;
}
.service-solutions .worries h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries h2 {
    display: block;
    font-size: 1.5rem;
    text-align: center;
  }
}
.service-solutions .worries h2 span.strong {
  display: inline-block;
  padding: 0 0.25rem;
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries h2 span.strong {
    font-size: 2rem;
  }
}
.service-solutions .worries h2 span.sp-inline-flex {
  display: inline-flex;
  align-items: center;
}
.service-solutions .worries__main-content {
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries__main-content {
    margin-top: 1.5rem;
  }
}
.service-solutions .worries__main-content .image {
  position: relative;
  z-index: 2;
  width: 56%;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries__main-content .image {
    width: 80%;
  }
}
.service-solutions .worries .worries-list {
  position: relative;
  z-index: 1;
  margin-top: -3.5rem;
  padding: 6.25rem 2.5rem 5rem;
  border: solid 5px #db0400;
  border-radius: 1rem;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries .worries-list {
    margin-top: -2.5rem;
    padding: 3.5rem 1.5rem 2.5rem;
  }
}
.service-solutions .worries .worries-list ul {
  display: flex;
  flex-direction: column;
  gap: 2.5rem 0;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries .worries-list ul {
    gap: 1rem 0;
  }
}
.service-solutions .worries .worries-list ul > li {
  display: flex;
  align-items: flex-start;
  gap: 0 1rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries .worries-list ul > li {
    gap: 0 0.5rem;
  }
}
.service-solutions .worries .worries-list ul > li span {
  display: inline-block;
  flex-shrink: 0;
  width: 2.25rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries .worries-list ul > li span {
    width: 1.25rem;
    padding-top: 0.25rem;
  }
}
.service-solutions .worries .worries-list p {
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries .worries-list p {
    font-size: 1.125rem;
    font-feature-settings: "palt";
    line-height: 1.4;
  }
}
.service-solutions .worries .worries-list .and-more {
  margin-top: 2.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .service-solutions .worries .worries-list .and-more {
    margin-top: 1rem;
  }
}
.service-solutions .our-solutions {
  max-width: unset !important;
  padding-top: 5rem;
  padding-right: unset !important;
  padding-left: unset !important;
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions {
    padding-top: unset;
  }
}
.service-solutions .our-solutions__heading {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}
.service-solutions .our-solutions__heading h2 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions__heading h2 {
    margin-top: 1rem;
    font-size: 1.125rem;
  }
}
.service-solutions .our-solutions__heading span.strong {
  display: inline-block;
  padding-right: 0.25rem;
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions__heading span.strong {
    padding-right: unset;
    font-size: 1.75rem;
  }
}
.service-solutions .our-solutions__heading span.line-2 {
  display: inline-block;
  margin-top: 0.5rem;
}
.service-solutions .our-solutions__heading .icon {
  position: absolute;
  top: 50%;
  width: 7.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions__heading .icon {
    position: static;
    width: 5rem;
  }
}
.service-solutions .our-solutions__heading .icon--men {
  left: -1.5rem;
  transform: translate(-100%, -50%);
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions__heading .icon--men {
    display: none;
  }
}
.service-solutions .our-solutions__heading .icon--women {
  right: -1.5rem;
  transform: translate(100%, -50%);
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions__heading .icon--women {
    right: unset;
    margin: -2.5rem auto 0;
    transform: unset;
  }
}
.service-solutions .our-solutions__message {
  width: 45rem;
  margin: 2.5rem auto 0;
  font-size: 1.125rem;
  line-height: 1.8;
}
@media screen and (max-width: 960px) {
  .service-solutions .our-solutions__message {
    width: 100%;
    margin: 1rem auto 0;
    padding: 0 5%;
    font-size: 1rem;
  }
}
.service-solutions .our-solutions__slider {
  width: 100%;
  margin-top: 2.5rem;
}
.service-solutions .services {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .services {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.service-solutions .services h2 {
  color: #db0400;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .service-solutions .services h2 {
    font-size: 2rem;
    text-align: center;
  }
}
.service-solutions .services__list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem 0;
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list {
    gap: 1.5rem 0;
    margin-top: 2rem;
  }
}
.service-solutions .services__list li {
  display: flex;
  align-items: center;
  gap: 0 2.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li {
    flex-direction: column;
    align-items: unset;
    gap: 1rem 0;
  }
}
.service-solutions .services__list li .image {
  flex-shrink: 0;
  width: 20rem;
  overflow: hidden;
  border-radius: 0.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li .image {
    width: 100%;
  }
}
.service-solutions .services__list li .details {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li .details {
    display: flex;
    flex-direction: column;
  }
}
.service-solutions .services__list li .details h3 {
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li .details h3 {
    font-size: 1.5rem;
  }
}
.service-solutions .services__list li .details p.explain {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li .details p.explain {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}
.service-solutions .services__list li .details a {
  display: inline-flex;
  align-items: center;
  gap: 0 0.5rem;
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li .details a {
    align-self: flex-end;
    margin-left: auto;
  }
}
.service-solutions .services__list li .details a:hover p::after {
  animation-name: link-hover;
}
.service-solutions .services__list li .details a p {
  position: relative;
  color: #db0400;
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .services__list li .details a p {
    font-size: 1rem;
  }
}
.service-solutions .services__list li .details a p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  background-color: #db0400;
}
.service-solutions .services__list li .details a .arrow {
  width: 2rem;
  padding: 0.5rem;
  border: solid 1px #db0400;
  border-radius: 100vh;
}
.service-solutions .flow {
  max-width: unset;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #e6f6f4;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.service-solutions .flow .content {
  width: 45rem;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow .content {
    width: 100%;
  }
}
.service-solutions .flow h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow h2 {
    font-size: 2rem;
    line-height: 1.2;
  }
}
.service-solutions .flow h2.questions-heading {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow h2.questions-heading {
    margin-top: 2.5rem;
  }
}
.service-solutions .flow h2 span {
  color: #00b292;
}
.service-solutions .flow .heading-sub {
  margin-top: 0.75rem;
  font-size: 1.25rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow .heading-sub {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}
.service-solutions .flow__list {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__list {
    margin-top: 1.5rem;
  }
}
.service-solutions .flow__list li.card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
}
.service-solutions .flow__list li.card h3 {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  color: #00b292;
  font-size: 1.25rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__list li.card h3 {
    font-size: 1.125rem;
    line-height: 1.2;
  }
}
.service-solutions .flow__list li.card h3 span {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
}
.service-solutions .flow__list li.card p.explain {
  margin-top: 0.75rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__list li.card p.explain {
    margin-top: 0.25rem;
  }
}
.service-solutions .flow__list li.card .tel {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: solid 1px #00b292;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__list li.card .tel {
    flex-direction: column;
    align-items: unset;
  }
}
.service-solutions .flow__list li.card .tel .tel-heading {
  font-weight: 700;
}
.service-solutions .flow__list li.card .tel a {
  display: inline-block;
  margin-left: 0.5rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 2.5rem;
  pointer-events: none;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__list li.card .tel a {
    margin-top: 0.5rem;
    margin-left: unset;
    font-size: 2rem;
    pointer-events: all;
  }
}
.service-solutions .flow__list li.card .tel .business-hour {
  font-size: 0.75rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__list li.card .tel .business-hour {
    margin-top: 0.25rem;
  }
}
.service-solutions .flow__list li.arrow {
  width: 2rem;
  margin: 0 auto;
}
.service-solutions .flow__questions {
  display: flex;
  align-items: stretch;
  gap: 0 1rem;
  margin-top: 2rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__questions {
    flex-direction: column;
    gap: 1.5rem 0;
    margin-top: 1.5rem;
  }
}
.service-solutions .flow__questions li {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  width: calc(50% - 0.5rem);
  padding-top: 1.625rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__questions li {
    width: 100%;
  }
}
.service-solutions .flow__questions li .question {
  padding-top: 1.25rem;
  padding-bottom: 0.875rem;
  background-color: #00b292;
  text-align: center;
}
.service-solutions .flow__questions li .question span {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  width: 4.875rem;
  height: 4.875rem;
  padding-top: 0.375rem;
  transform: translateX(-50%);
  border-radius: 100vh;
  background-color: #00b292;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
}
.service-solutions .flow__questions li .question h3 {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
}
.service-solutions .flow__questions .answer {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: solid 1px #00b292;
  background-color: #fff;
}
.service-solutions .flow__questions .answer .answer-main {
  display: flex;
  gap: 0 0.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .flow__questions .answer .answer-main {
    font-size: 1.125rem;
  }
}
.service-solutions .flow__questions .answer .answer-main span {
  color: #00b292;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
.service-solutions .flow__questions .answer .answer-main p {
  color: #00b292;
  font-weight: 700;
  line-height: 1.4;
}
.service-solutions .flow__questions .answer .message {
  margin-top: 0.5rem;
  line-height: 1.4;
}
.service-solutions .contact {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact {
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
  }
}
.service-solutions .contact .content {
  max-width: 1260px;
  margin: 0 auto;
  padding: 2.5rem;
  border: solid 3px #00b292;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact .content {
    padding: 1.5rem 0 2rem;
  }
}
.service-solutions .contact h2 {
  color: #00b292;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact h2 {
    padding: 1rem 0;
    background-color: #00b292;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
.service-solutions .contact__tel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact__tel-wrapper {
    flex-direction: column;
  }
}
.service-solutions .contact__tel-wrapper .tel-heading {
  margin-right: 2rem;
  padding: 1rem 2rem 1rem 0;
  border-right: solid 4px #00b292;
  color: #00b292;
  font-size: 1.5rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact__tel-wrapper .tel-heading {
    padding: unset;
    border-right: unset;
    font-size: 1.125rem;
  }
  .service-solutions .contact__tel-wrapper .tel-heading::after {
    content: "";
    display: block;
    width: 3rem;
    height: 4px;
    margin: 0.5rem auto 0;
    background-color: #00b292;
  }
}
.service-solutions .contact__tel-wrapper .tel-number {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact__tel-wrapper .tel-number {
    gap: 0 0.25rem;
    margin-top: 1rem;
  }
}
.service-solutions .contact__tel-wrapper .tel-number .icon {
  display: block;
  width: 2.5rem;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact__tel-wrapper .tel-number .icon {
    width: 1.75rem;
  }
}
.service-solutions .contact__tel-wrapper .tel-number a {
  display: inline-block;
  color: #00b292;
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  pointer-events: none;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact__tel-wrapper .tel-number a {
    font-size: 2.5rem;
    pointer-events: all;
  }
}
.service-solutions .contact__tel-wrapper .business-hour {
  color: #00b292;
  font-size: 1.125rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .service-solutions .contact__tel-wrapper .business-hour {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}

.services-surveying .surveying-01__flow {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  margin-top: 1.5rem;
}
.services-surveying .surveying-01__flow > li.card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #fff3ef;
  counter-increment: number;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card {
    padding: 1.5rem 1rem;
  }
}
.services-surveying .surveying-01__flow > li.card h4 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card h4 {
    font-size: 1.125rem;
  }
}
.services-surveying .surveying-01__flow > li.card h4::before {
  content: counter(number);
  display: block;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  border-right: solid 1px #000;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card h4::before {
    margin-right: 0.375rem;
    padding-right: 0.375rem;
  }
}
.services-surveying .surveying-01__flow > li.card p.explain {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card p.explain {
    margin-top: 0.75rem;
    font-size: 0.875rem;
  }
}
.services-surveying .surveying-01__flow > li.card .tel {
  display: flex;
  gap: 0 0.25rem;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card .tel {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
  }
}
.services-surveying .surveying-01__flow > li.card .tel .tel-heading {
  transform: translateY(10%);
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card .tel .tel-heading {
    transform: unset;
    font-size: 0.875rem;
  }
}
.services-surveying .surveying-01__flow > li.card .tel > a {
  display: inline-block;
  color: #000;
  font-size: 2rem;
  pointer-events: none;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card .tel > a {
    margin-top: 0.5rem;
    pointer-events: all;
  }
}
.services-surveying .surveying-01__flow > li.card .tel .business-hour {
  transform: translateY(15%);
  font-size: 0.75rem;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-01__flow > li.card .tel .business-hour {
    margin-top: 0.25rem;
    transform: unset;
  }
}
.services-surveying .surveying-01__flow > li.arrow div {
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  width: 1.5rem;
  height: 0.75rem;
  margin: 0 auto;
  background-color: #db0400;
}
.services-surveying .surveying-02 {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-02 {
    margin-top: 2.5rem;
  }
}
.services-surveying .surveying-02__service-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-02__service-list {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.services-surveying .surveying-02__service-list > li {
  width: calc(50% - 0.5rem);
  padding: 1.5rem;
  border: solid 1px #db0400;
}
@media screen and (max-width: 960px) {
  .services-surveying .surveying-02__service-list > li {
    width: 100%;
    padding: 1.5rem 1rem;
  }
}
.services-surveying .surveying-02__service-list > li h3 {
  color: #db0400;
  font-size: 1.25rem;
  text-align: center;
}
.services-surveying .surveying-02__service-list > li p {
  margin-top: 0.5rem;
  line-height: 1.6;
  word-break: break-all;
}

.services-registration .registration-01__land, .services-registration .registration-01__building {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  margin-top: 1rem;
}
.services-registration .registration-01__land > li, .services-registration .registration-01__building > li {
  padding: 1.5rem;
  border: solid 1px #db0400;
}
@media screen and (max-width: 960px) {
  .services-registration .registration-01__land > li, .services-registration .registration-01__building > li {
    padding: 1.5rem 1rem;
  }
}
.services-registration .registration-01__land > li h4, .services-registration .registration-01__building > li h4 {
  padding-bottom: 1rem;
  border-bottom: solid 1px #db0400;
  color: #db0400;
  font-size: 1.125rem;
}
.services-registration .registration-01__land > li .main-content, .services-registration .registration-01__building > li .main-content {
  display: flex;
  align-items: center;
  gap: 0 2.5rem;
  margin-top: 1.5rem;
}
@media screen and (max-width: 960px) {
  .services-registration .registration-01__land > li .main-content, .services-registration .registration-01__building > li .main-content {
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem 0;
  }
}
.services-registration .registration-01__land > li .main-content .image, .services-registration .registration-01__building > li .main-content .image {
  flex-shrink: 0;
  width: 13.75rem;
}
@media screen and (max-width: 960px) {
  .services-registration .registration-01__land > li .main-content .image, .services-registration .registration-01__building > li .main-content .image {
    width: auto;
    height: 7.5rem;
  }
  .services-registration .registration-01__land > li .main-content .image img, .services-registration .registration-01__building > li .main-content .image img {
    width: auto;
    height: 100%;
  }
}
@media screen and (max-width: 960px) {
  .services-registration .registration-01__land > li .main-content .image.type-07, .services-registration .registration-01__building > li .main-content .image.type-07 {
    height: 4.5rem;
  }
}
.services-registration .registration-01__land > li .main-content .explain, .services-registration .registration-01__building > li .main-content .explain {
  line-height: 1.4;
}
.services-registration .registration-01__land > li .main-content .explain small, .services-registration .registration-01__building > li .main-content .explain small {
  font-size: 0.625rem;
}
.services-registration .registration-01__land > li .main-content .explain span.attention, .services-registration .registration-01__building > li .main-content .explain span.attention {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.services-civil-engineering .civil-engineering-02,
.services-civil-engineering .civil-engineering-03 {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .services-civil-engineering .civil-engineering-02,
.services-civil-engineering .civil-engineering-03 {
    margin-top: 2.5rem;
  }
}

.services-development-support h2.normal-heading {
  line-height: 1.2;
}
.services-development-support p.attention {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.6;
}
@media screen and (max-width: 960px) {
  .services-development-support p.attention {
    font-size: 0.75rem;
    line-height: 1.4;
  }
}
.services-development-support ul.point-list {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1.5rem;
  list-style-type: disc;
  border-radius: 0.5rem;
  background-color: #edf0ef;
  gap: 1rem 0;
}
@media screen and (max-width: 960px) {
  .services-development-support ul.point-list {
    padding: 1rem;
    gap: 0.5rem 0;
  }
}
.services-development-support ul.point-list > li {
  margin-left: 1.5rem;
  color: #000;
  font-size: 1.125rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .services-development-support ul.point-list > li {
    font-size: 1rem;
  }
}
.services-development-support .development-support-02,
.services-development-support .development-support-03,
.services-development-support .development-support-04,
.services-development-support .development-support-05,
.services-development-support .development-support-06,
.services-development-support .development-support-07,
.services-development-support .development-support-08,
.services-development-support .development-support-09,
.services-development-support .development-support-10,
.services-development-support .development-support-11 {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-02,
.services-development-support .development-support-03,
.services-development-support .development-support-04,
.services-development-support .development-support-05,
.services-development-support .development-support-06,
.services-development-support .development-support-07,
.services-development-support .development-support-08,
.services-development-support .development-support-09,
.services-development-support .development-support-10,
.services-development-support .development-support-11 {
    margin-top: 2.5rem;
  }
}
.services-development-support .development-support-01__table {
  display: grid;
  grid-template-columns: 33.33%;
  grid-template-rows: 1fr;
  margin-top: 1.5rem;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table {
    grid-template-columns: 50%;
    grid-template-rows: auto auto auto 1rem auto auto 1rem auto auto;
    margin-top: 1rem;
  }
}
.services-development-support .development-support-01__table .table-heading,
.services-development-support .development-support-01__table .table-cell {
  padding: 1rem;
  border-bottom: solid 1px #b1b1b1;
  border-left: solid 1px #b1b1b1;
  font-size: 0.875rem;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-heading,
.services-development-support .development-support-01__table .table-cell {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-heading {
    padding: 0.75rem;
    background-color: #edf0ef;
    font-size: 1rem;
    line-height: 1.2;
  }
}
.services-development-support .development-support-01__table .table-heading--01 {
  display: flex;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 1;
  align-items: center;
  border-top: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-heading--01 {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
    justify-content: center;
    border-right: solid 1px #b1b1b1;
  }
}
.services-development-support .development-support-01__table .table-heading--02 {
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-row-start: 3;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-heading--02 {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-row-start: 5;
    border-top: solid 1px #b1b1b1;
    border-right: solid 1px #b1b1b1;
    text-align: center;
  }
}
.services-development-support .development-support-01__table .table-heading--03 {
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 5;
  grid-row-start: 4;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-heading--03 {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-end: 9;
    grid-row-start: 8;
    border-top: solid 1px #b1b1b1;
    border-right: solid 1px #b1b1b1;
    text-align: center;
  }
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell p {
    text-align: center;
  }
}
.services-development-support .development-support-01__table .table-cell--01 {
  grid-column-end: 3;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-row-start: 1;
  border-top: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell--01 {
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-row-start: 2;
    border-top: unset;
    border-bottom: dashed 1px #b1b1b1;
  }
}
.services-development-support .development-support-01__table .table-cell--02 {
  grid-column-end: 3;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-row-start: 2;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell--02 {
    grid-column-end: 3;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-row-start: 2;
    border-right: solid 1px #b1b1b1;
    border-bottom: dashed 1px #b1b1b1;
  }
}
.services-development-support .development-support-01__table .table-cell--03 {
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-row-start: 1;
  border-top: solid 1px #b1b1b1;
  border-right: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell--03 {
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-row-start: 3;
    border-top: unset;
    border-right: unset;
  }
}
.services-development-support .development-support-01__table .table-cell--04 {
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-row-start: 2;
  border-right: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell--04 {
    grid-column-end: 3;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-row-start: 3;
  }
}
.services-development-support .development-support-01__table .table-cell--05 {
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 4;
  grid-row-start: 3;
  border-right: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell--05 {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-end: 7;
    grid-row-start: 6;
  }
}
.services-development-support .development-support-01__table .table-cell--06 {
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 5;
  grid-row-start: 4;
  border-right: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-01__table .table-cell--06 {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-end: 10;
    grid-row-start: 9;
  }
}
.services-development-support .development-support-08__table {
  display: table;
  width: 100%;
  margin-top: 1.5rem;
  border-collapse: collapse;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-08__table {
    margin-top: 2rem;
  }
}
.services-development-support .development-support-08__table .table-header {
  display: table-header-group;
  font-weight: 700;
}
.services-development-support .development-support-08__table .table-header .table-cell {
  padding: 0.75rem 0.5rem;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-08__table .table-header .table-cell {
    font-size: 0.875rem;
  }
}
.services-development-support .development-support-08__table .table-row {
  display: table-row;
  width: 100%;
}
.services-development-support .development-support-08__table .table-cell {
  display: table-cell;
  width: 20.5%;
  padding: 0.75rem;
  border: solid 1px #b1b1b1;
  font-size: 0.875rem;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-08__table .table-cell {
    width: 33%;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.4;
    vertical-align: middle;
  }
}
.services-development-support .development-support-08__table .table-cell:first-of-type {
  width: 79.5%;
}
@media screen and (max-width: 960px) {
  .services-development-support .development-support-08__table .table-cell:first-of-type {
    width: 67%;
  }
}

.recruit {
  padding-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit {
    padding-bottom: 3.5rem;
  }
}
.recruit .recruit-message {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message {
    margin-top: 3.5rem;
  }
}
.recruit .recruit-message .content {
  display: flex;
  position: relative;
  gap: 0 2.75rem;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message .content {
    flex-direction: column-reverse;
  }
}
.recruit .recruit-message__slider {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__slider {
    margin-top: 3rem;
  }
}
.recruit .recruit-message__slider__image {
  overflow: hidden;
  border-radius: 0.5rem;
}
.recruit .recruit-message__main-content {
  flex-shrink: 0;
  writing-mode: vertical-rl;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content {
    writing-mode: unset;
  }
}
.recruit .recruit-message__main-content h2 {
  margin-left: 4rem;
  font-size: 2rem;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content h2 {
    margin-left: unset;
    font-size: 1.5rem;
  }
}
.recruit .recruit-message__main-content h2 span.strong {
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content h2 span.strong {
    display: inline-block;
    margin-right: -0.5em;
    margin-left: -0.5em;
    font-size: 2.5rem;
  }
}
.recruit .recruit-message__main-content h2 span.red {
  color: #db0400;
}
.recruit .recruit-message__main-content h2 span.shift-position {
  display: inline-block;
  margin-right: 1rem;
  transform: translateY(-0.8em);
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content h2 span.shift-position {
    margin-top: 0.5rem;
    margin-right: unset;
    transform: unset;
  }
}
.recruit .recruit-message__main-content h2 span.shift-position-sub {
  display: inline-block;
  transform: translateY(-0.4em);
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content h2 span.shift-position-sub {
    transform: unset;
  }
}
.recruit .recruit-message__main-content .message-text-wrapper {
  font-size: 1.125rem;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content .message-text-wrapper {
    margin-top: 2rem;
    font-size: 1rem;
    letter-spacing: 0;
  }
}
.recruit .recruit-message__main-content .message-text-wrapper:not(:first-of-type) {
  margin-right: 2.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content .message-text-wrapper:not(:first-of-type) {
    margin-top: 2rem;
    margin-right: unset;
  }
}
.recruit .recruit-message__main-content .message-text-wrapper p:not(:first-of-type) {
  margin-right: 0.75rem;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message__main-content .message-text-wrapper p:not(:first-of-type) {
    margin-top: 1rem;
    margin-right: unset;
  }
}
.recruit .recruit-message .decoration-text {
  position: absolute;
  bottom: -2.5rem;
  left: 26%;
  width: 46%;
}
@media screen and (max-width: 960px) {
  .recruit .recruit-message .decoration-text {
    bottom: 36%;
    left: 50%;
    width: 100%;
    transform: translate(-50% 0);
  }
}
.recruit .our-works {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .our-works {
    margin-top: 3.5rem;
  }
}
.recruit .our-works h2 {
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .our-works h2 {
    font-size: 1.5rem;
  }
}
.recruit .our-works__main-content {
  display: flex;
  flex-direction: column;
  gap: 3.5rem 0;
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .our-works__main-content {
    margin-top: 1.5rem;
    gap: 2rem 0;
  }
}
.recruit .our-works__main-content li {
  counter-increment: list-number 1;
}
.recruit .our-works__main-content .heading {
  position: relative;
  width: 100%;
  height: auto;
}
.recruit .our-works__main-content .heading .image {
  overflow: hidden;
  border-radius: 0.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .our-works__main-content .heading .image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.4) 100%);
  }
}
.recruit .our-works__main-content .heading h3 {
  position: absolute;
  bottom: 1.5rem;
  left: 0.5rem;
  color: #fff;
  font-family: "Shippori Mincho", serif;
  font-size: 3rem;
  text-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 960px) {
  .recruit .our-works__main-content .heading h3 {
    bottom: 1rem;
    font-size: 2rem;
    text-shadow: unset;
  }
}
.recruit .our-works__main-content .heading h3::before {
  content: "0" counter(list-number);
  display: inline-block;
  margin-right: 0.5rem;
}
.recruit .our-works__main-content .text-wrapper {
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .our-works__main-content .text-wrapper {
    margin-top: 1rem;
  }
}
.recruit .our-works__main-content .text-wrapper h3 {
  font-size: 2rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .recruit .our-works__main-content .text-wrapper h3 {
    font-size: 1.25rem;
  }
}
.recruit .our-works__main-content .text-wrapper p {
  max-width: 45rem;
  margin: 1rem auto 0;
  font-size: 1.125rem;
  line-height: 1.8;
}
@media screen and (max-width: 960px) {
  .recruit .our-works__main-content .text-wrapper p {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.6;
  }
}
.recruit .interview {
  max-width: 1440px;
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview {
    margin-top: 3.5rem;
  }
}
.recruit .interview h2 {
  padding: 0 5rem;
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview h2 {
    padding: 0 5%;
    font-size: 1.5rem;
  }
}
.recruit .interview__explain-text {
  margin-top: 2.5rem;
  padding: 0 5rem;
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__explain-text {
    margin-top: 1rem;
    padding: 0 5%;
    font-size: 0.875rem;
  }
}
.recruit .interview__main-content {
  display: flex;
  flex-direction: column;
  gap: 3.5rem 0;
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content {
    margin-top: 2rem;
    gap: 2.5rem 0;
  }
}
.recruit .interview__main-content > li.active .interview-wrapper {
  padding: 2.5rem 5rem 3.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content > li.active .interview-wrapper {
    padding: 2rem 5% 3.5rem;
  }
}
.recruit .interview__main-content .main-heading {
  position: relative;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .main-heading {
    overflow: hidden;
  }
}
.recruit .interview__main-content .description {
  display: flex;
  position: absolute;
  top: 0;
  left: 5rem;
  align-items: center;
  width: fit-content;
  height: 100%;
  padding: 1.5rem;
  background-color: #db0400;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .description {
    position: static;
    width: 100%;
    height: auto;
    padding: 1.25rem 5%;
  }
}
.recruit .interview__main-content .description .decoration {
  position: absolute;
  right: 0;
  bottom: 25%;
  width: fit-content;
  height: 3.5rem;
  transform: translateX(2.5rem);
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .description .decoration {
    bottom: 3rem;
    transform: translateX(1.5rem);
  }
}
.recruit .interview__main-content .description .decoration img {
  width: auto;
  height: 100%;
}
.recruit .interview__main-content .description .text-wrapper {
  display: flex;
  gap: 0 0.75rem;
  align-items: center;
  color: #fff;
}
.recruit .interview__main-content .description .text-wrapper .number {
  font-family: "Shippori Mincho", serif;
  font-size: 4.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .description .text-wrapper .number {
    font-size: 2.5rem;
  }
}
.recruit .interview__main-content .description .text-wrapper .details {
  transform: translateY(10%);
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .description .text-wrapper .details {
    transform: unset;
  }
}
.recruit .interview__main-content .description .text-wrapper .unit {
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .description .text-wrapper .unit {
    font-size: 0.875rem;
  }
}
.recruit .interview__main-content .description .text-wrapper .join {
  margin-top: 0.25rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .description .text-wrapper .join {
    font-size: 1.25rem;
  }
}
.recruit .interview__main-content .interview-wrapper {
  padding: 1.5rem 5rem;
  transition: all 600ms;
  border-right: solid 1px #db0400;
  border-bottom: solid 1px #db0400;
  border-left: solid 1px #db0400;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper {
    padding: 1.5rem 5%;
  }
}
.recruit .interview__main-content .interview-wrapper .interview-content {
  display: flex;
  align-items: flex-start;
  gap: 0 2.5rem;
  height: 0;
  overflow: hidden;
  transition: all 600ms;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper .interview-content {
    flex-direction: column-reverse;
  }
}
.recruit .interview__main-content .interview-wrapper .interview-content.active {
  overflow: visible;
}
.recruit .interview__main-content .interview-wrapper .interview-content.active .image {
  position: sticky;
  top: 2.5rem;
}
.recruit .interview__main-content .interview-wrapper .image {
  flex-shrink: 0;
  width: 37%;
  overflow: hidden;
  border-radius: 0.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper .image {
    position: static !important;
    width: 100%;
  }
}
.recruit .interview__main-content .interview-wrapper .text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem 0;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper .text-wrapper {
    gap: 2rem 0;
    padding-bottom: 2rem;
  }
}
.recruit .interview__main-content .interview-wrapper .text-wrapper h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper .text-wrapper h3 {
    font-size: 1.25rem;
  }
}
.recruit .interview__main-content .interview-wrapper .text-wrapper h3::after {
  content: "";
  display: block;
  width: 3.5rem;
  height: 4px;
  margin-top: 1rem;
  background-color: #db0400;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper .text-wrapper h3::after {
    margin-top: 0.5rem;
  }
}
.recruit .interview__main-content .interview-wrapper .text-wrapper p {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-wrapper .text-wrapper p {
    margin-top: 1rem;
    font-size: 0.9375rem;
    /* stylelint-disable-line */
  }
}
.recruit .interview__main-content .interview-toggle {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  border: solid 1px #db0400;
  cursor: pointer;
}
.recruit .interview__main-content .interview-toggle.active {
  margin-top: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .interview__main-content .interview-toggle.active {
    margin-top: 2rem;
  }
}
.recruit .interview__main-content .interview-toggle.active .state span:nth-child(2) {
  transform: translate(-50%) rotate(0);
}
.recruit .interview__main-content .interview-toggle .state {
  position: relative;
  width: 1.625rem;
  height: auto;
  aspect-ratio: 1/1;
  border-right: solid 1px #db0400;
}
.recruit .interview__main-content .interview-toggle .state span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.875rem;
  height: 1px;
  transition: all 300ms;
  background-color: #db0400;
}
.recruit .interview__main-content .interview-toggle .state span:nth-child(1) {
  transform: translate(-50%);
}
.recruit .interview__main-content .interview-toggle .state span:nth-child(2) {
  transform: translate(-50%) rotate(90deg);
}
.recruit .interview__main-content .interview-toggle p {
  padding: 0 0.5rem;
  color: #db0400;
  font-size: 1.125rem;
}
.recruit .requirements {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements {
    margin-top: 3.5rem;
  }
}
.recruit .requirements h2 {
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements h2 {
    font-size: 1.5rem;
  }
}
.recruit .requirements h3 {
  padding-left: 0.5rem;
  border-left: solid 0.25rem #db0400;
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements h3 {
    font-size: 1.125rem;
  }
}
.recruit .requirements h3.heading-full-time {
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements h3.heading-full-time {
    margin-top: 2rem;
  }
}
.recruit .requirements h3.heading-part-time {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements h3.heading-part-time {
    margin-top: 2rem;
  }
}
.recruit .requirements h3 + h4.sub-heading {
  margin-top: 1.5rem;
}
.recruit .requirements h4.sub-heading {
  margin-top: 2.5rem;
  color: #db0400;
  font-size: 1.25rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements h4.sub-heading {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.recruit .requirements__table-wrapper {
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .recruit .requirements__table-wrapper {
    margin-top: 0.5rem;
  }
}
.recruit .requirements__table-wrapper .table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.recruit .requirements__table-wrapper .table .table-row {
  display: table-row-group;
}
.recruit .requirements__table-wrapper .table .table-cell {
  display: table-cell;
  padding: 1rem;
  border: solid 1px #db0400;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .recruit .requirements__table-wrapper .table .table-cell {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
.recruit .requirements__table-wrapper .table .table-cell:nth-child(1) {
  width: 20%;
  vertical-align: middle;
}
@media screen and (max-width: 960px) {
  .recruit .requirements__table-wrapper .table .table-cell:nth-child(1) {
    width: 23%;
  }
}
.recruit .requirements__table-wrapper .table .table-cell:nth-child(2) {
  width: 80%;
}
@media screen and (max-width: 960px) {
  .recruit .requirements__table-wrapper .table .table-cell:nth-child(2) {
    width: 77%;
  }
}
.recruit .requirements__stop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  padding: 5rem 0;
  border-radius: 0.5rem;
  background-color: #edf0ef;
}
.recruit .faq {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .faq {
    margin-top: 3.5rem;
  }
}
.recruit .faq h2 {
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .faq h2 {
    font-size: 1.5rem;
  }
}
.recruit .faq__list {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
  margin-top: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .faq__list {
    margin-top: 1.5rem;
    gap: 1.5rem 0;
  }
}
.recruit .faq__list > li {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  padding-bottom: 2rem;
  border-bottom: solid 1px #db0400;
}
@media screen and (max-width: 960px) {
  .recruit .faq__list > li {
    gap: 0.75rem 0;
    padding-bottom: 1rem;
  }
}
.recruit .faq__list > li .question,
.recruit .faq__list > li .answer {
  display: flex;
  align-items: flex-start;
  gap: 0 0.75rem;
}
.recruit .faq__list > li .question span,
.recruit .faq__list > li .answer span {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100vh;
}
@media screen and (max-width: 960px) {
  .recruit .faq__list > li .question span,
.recruit .faq__list > li .answer span {
    width: 1.625rem;
    height: 1.625rem;
  }
}
.recruit .faq__list > li .question span img,
.recruit .faq__list > li .answer span img {
  width: 1rem;
}
@media screen and (max-width: 960px) {
  .recruit .faq__list > li .question span img,
.recruit .faq__list > li .answer span img {
    width: 0.625rem;
  }
}
.recruit .faq__list > li .question p,
.recruit .faq__list > li .answer p {
  margin-top: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .recruit .faq__list > li .question p,
.recruit .faq__list > li .answer p {
    margin-top: 0.125rem;
    font-size: 1rem;
  }
}
.recruit .faq__list > li .question > span {
  background-color: #db0400;
}
.recruit .faq__list > li .answer > span {
  border: solid 1px #db0400;
  background-color: #fff;
}
.recruit .flow {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow {
    margin-top: 3.5rem;
  }
}
.recruit .flow h2 {
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow h2 {
    font-size: 1.5rem;
  }
}
.recruit .flow__main-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content {
    margin-top: 1.5rem;
  }
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content li {
    border: solid 1px #db0400;
  }
}
.recruit .flow__main-content .heading {
  display: flex;
  align-items: center;
  border: solid 1px #db0400;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content .heading {
    flex-direction: column;
    border: unset;
  }
}
.recruit .flow__main-content .heading .label {
  padding: 0.5rem 1rem;
  background-color: #db0400;
  color: #fff;
  font-size: 1.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content .heading .label {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.875rem;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.recruit .flow__main-content .heading .label span {
  font-size: 2.5rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content .heading .label span {
    font-size: 1.25rem;
  }
}
.recruit .flow__main-content .heading h3 {
  padding-left: 1rem;
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content .heading h3 {
    width: 100%;
    margin-top: 1.5rem;
    padding: 0 1rem;
  }
}
.recruit .flow__main-content .explain {
  margin-top: 1rem;
  padding-left: 8.75rem;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content .explain {
    margin-top: 0.75rem;
    padding: 0 1rem 1.5rem;
  }
}
.recruit .flow__main-content .explain p {
  font-size: 1.125rem;
  line-height: 1.6;
}
@media screen and (max-width: 960px) {
  .recruit .flow__main-content .explain p {
    font-size: 1rem;
  }
}
.recruit .entry {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .recruit .entry {
    margin-top: 3.5rem;
  }
}
.recruit .entry h2 {
  color: #db0400;
  font-size: 3rem;
}
@media screen and (max-width: 960px) {
  .recruit .entry h2 {
    font-size: 1.5rem;
  }
}
.recruit .entry__main-content {
  margin-top: 2.5rem;
  padding: 2.5rem;
  border: solid 1px #00b292;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content {
    margin-top: 1.5rem;
    padding: 2rem 1rem;
  }
}
.recruit .entry__main-content--web {
  border: solid 1px #db0400;
}
.recruit .entry__main-content p.explain {
  font-size: 1.125rem;
  line-height: 1.6;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content p.explain {
    font-size: 0.8125rem;
    /* stylelint-disable-line */
    text-align: left;
  }
}
.recruit .entry__main-content p.heading {
  font-size: 1.25rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content p.heading {
    font-size: 1.125rem;
    line-height: 1.4;
  }
}
.recruit .entry__main-content .tel-wrapper {
  width: fit-content;
  margin: 2rem auto 0;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content .tel-wrapper {
    margin-top: 1rem;
  }
}
.recruit .entry__main-content .tel-wrapper .tel-heading {
  font-size: 1.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content .tel-wrapper .tel-heading {
    font-size: 1.125rem;
  }
}
.recruit .entry__main-content .tel-wrapper .tel-number {
  display: inline-block;
  margin-top: 0.5rem;
  color: #000;
  font-size: 4rem;
  pointer-events: none;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content .tel-wrapper .tel-number {
    font-size: 2.5rem;
    pointer-events: all;
  }
}
.recruit .entry__main-content .tel-wrapper .business-hour {
  margin-top: 0.5rem;
  padding: 0.25rem;
  background-color: #00b292;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content .tel-wrapper .business-hour {
    font-size: 0.75rem;
  }
}
.recruit .entry__main-content a.button {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  width: fit-content;
  margin: 2.5rem auto 0;
  padding: 1rem 2rem;
  transition: all 300ms;
  border: solid 1px #db0400;
  color: #db0400;
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content a.button {
    font-size: 1rem;
  }
}
.recruit .entry__main-content a.button:hover {
  background-color: #db0400;
  color: #fff;
}
.recruit .entry__main-content a.button .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: solid 1px #db0400;
  border-radius: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content a.button .arrow {
    width: 2rem;
    height: 2rem;
  }
}
.recruit .entry__main-content a.button .arrow img {
  width: 1rem;
}
@media screen and (max-width: 960px) {
  .recruit .entry__main-content a.button .arrow img {
    width: 0.75rem;
  }
}

.works .construction {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .works .construction {
    margin-top: 3.5rem;
  }
}
.works .construction__item {
  display: flex;
  gap: 2.5rem;
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .works .construction__item {
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }
}
.works .construction__item:first-child {
  margin-top: 0;
}
.works .construction__slider {
  width: 48%;
}
@media screen and (max-width: 960px) {
  .works .construction__slider {
    width: 100%;
  }
}
.works .construction__slider .splide__arrow {
  width: auto;
  height: 1.383rem;
  background-color: transparent;
}
@media screen and (max-width: 960px) {
  .works .construction__slider .splide__arrow {
    height: 1.038rem;
  }
}
.works .construction__slider .splide__arrow svg {
  width: auto;
  height: 100%;
  fill: #db0400;
}
.works .construction__slider .splide__pagination {
  bottom: 0;
  margin-bottom: 1rem;
  gap: 1rem;
}
@media screen and (max-width: 960px) {
  .works .construction__slider .splide__pagination {
    margin-bottom: 0.475rem;
    gap: 0.5rem;
  }
}
.works .construction__slider .splide__pagination__page {
  width: 0.75rem;
  height: 0.75rem;
  margin: unset;
  background-color: #fff;
}
@media screen and (max-width: 960px) {
  .works .construction__slider .splide__pagination__page {
    width: 0.5rem;
    height: 0.5rem;
  }
}
.works .construction__slider .splide__pagination__page.is-active {
  transform: unset;
  background-color: #db0400;
}
.works .construction h3 {
  padding-left: 1rem;
  border-left: solid 0.375rem #db0400;
  font-size: 2rem;
  line-height: 1.2;
}
@media screen and (max-width: 960px) {
  .works .construction h3 {
    padding-left: 0.625rem;
    border-left: solid 0.125rem #db0400;
    font-size: 1.125rem;
  }
}
.works .construction__detail {
  flex: 1;
}
.works .construction__detail table {
  width: 100%;
  margin-top: 2rem;
}
@media screen and (max-width: 960px) {
  .works .construction__detail table {
    margin-top: unset;
  }
}
.works .construction__detail table td {
  width: 70%;
  padding: 1rem;
  border-collapse: collapse;
  border: 1px solid #b1b1b1;
}
@media screen and (max-width: 960px) {
  .works .construction__detail table td {
    padding: 0.75rem;
    font-size: 0.75rem;
  }
}
.works .construction__detail table td:first-of-type {
  width: 30%;
}
@media screen and (max-width: 960px) {
  .works .construction__detail table td:first-of-type {
    width: 40%;
  }
}
.works .history {
  margin-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .works .history {
    margin-top: 3.539rem;
    padding-bottom: 3.5rem;
  }
}
.works .history h2 {
  position: relative;
  width: 100%;
  padding: 0.875rem 1.5rem;
  border: solid 1px #db0400;
  color: #db0400;
  font-size: 1.25rem;
}
@media screen and (max-width: 960px) {
  .works .history h2 {
    padding: 0.938rem 1.5rem;
    font-size: 1.125rem;
  }
}
.works .history h2::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1rem;
  height: 1px;
  background-color: #db0400;
}
.works .history__scroll-layer {
  margin-top: 1.5rem;
}
@media screen and (max-width: 960px) {
  .works .history__scroll-layer {
    position: relative;
    margin-top: 1rem;
    overflow: hidden;
    overflow-x: scroll;
  }
}
.works .history__scroll-layer .scroll-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.5rem;
  transform: translate(-50%, -50%);
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
.works .history__scroll-layer .scroll-hint.hide {
  transition: 300ms;
  opacity: 0;
}
.works .history__table {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .works .history__table {
    width: 195.3%;
  }
}
.works .history__table td {
  padding: 0.75rem;
}
@media screen and (max-width: 960px) {
  .works .history__table td {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}
.works .history__table td:nth-of-type(1) {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .works .history__table td:nth-of-type(1) {
    width: 47%;
  }
}
.works .history__table td:nth-of-type(2) {
  width: 12.5%;
}
@media screen and (max-width: 960px) {
  .works .history__table td:nth-of-type(2) {
    width: 22.7%;
  }
}
.works .history__table td:nth-of-type(3) {
  width: 37.5%;
}
@media screen and (max-width: 960px) {
  .works .history__table td:nth-of-type(3) {
    width: 30.3%;
  }
}
.works .history__table .title {
  border: solid 1px #db0400;
  background-color: #db0400;
  color: #fff;
}
.works .history__table .title td + td {
  border-left: solid 1px #fff;
}
.works .history__table .year {
  border: solid 1px #b1b1b1;
  border-top: unset;
  background-color: #898989;
  color: #fff;
}
.works .history__table .detail td {
  border: solid 1px #b1b1b1;
  border-top: unset;
}

.privacy-policy .privacy {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.privacy-policy .privacy__detail {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy__detail {
    margin-top: 2rem;
  }
}
.privacy-policy .privacy h2 {
  margin-top: 2.5rem;
  color: #db0400;
  font-size: 1.125rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy h2 {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.privacy-policy .privacy h2:first-of-type {
  margin-top: 0;
}
.privacy-policy .privacy h2 + p {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy h2 + p {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}
.privacy-policy .privacy__link {
  display: flex;
  align-items: end;
  width: fit-content;
  transition: all 300ms;
  outline: none;
  color: #000;
  text-decoration: underline;
  gap: 0.25rem;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.08em;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy__link {
    font-size: 0.875rem;
  }
}
.privacy-policy .privacy__link:first-of-type {
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy__link:first-of-type {
    margin-top: 0.5rem;
  }
}
.privacy-policy .privacy__link:last-of-type {
  margin-top: 0.688rem;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy__link:last-of-type {
    margin-top: 0.5rem;
  }
}
.privacy-policy .privacy__link:hover {
  opacity: 0.6;
}
.privacy-policy .privacy__link .icon {
  width: 0.625rem;
  height: auto;
}
.privacy-policy .privacy__date {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .privacy-policy .privacy__date {
    margin-top: 1.5rem;
    font-size: 0.875rem;
  }
}
.privacy-policy .privacy__tel {
  color: #000;
  pointer-events: none;
}

.company .company-message {
  margin-top: 5rem;
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 960px) {
  .company .company-message {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}
.company .company-message h2 {
  position: relative;
  color: #db0400;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .company-message h2 {
    font-size: 2rem;
  }
}
.company .company-message h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  width: 1px;
  height: 2rem;
  transform: translateY(100%);
  background-color: #db0400;
}
@media screen and (max-width: 960px) {
  .company .company-message h2::after {
    display: none;
  }
}
.company .company-message__main-message {
  position: relative;
  margin-top: 4rem;
}
@media screen and (max-width: 960px) {
  .company .company-message__main-message {
    margin-top: 1rem;
  }
}
.company .company-message__main-message p {
  font-family: "Shippori Mincho", serif;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .company-message__main-message p {
    font-size: 1.25rem;
  }
}
.company .company-message__main-message .decoration-text {
  position: absolute;
  z-index: -1;
  bottom: -0.6rem;
  left: 50%;
  width: 37.375rem;
  transform: translateX(-50%);
}
@media screen and (max-width: 960px) {
  .company .company-message__main-message .decoration-text {
    top: 0;
    width: 17.938rem;
    transform: translateX(-50%) translateY(-30%);
  }
}
.company .company-message__content {
  display: flex;
  margin-top: 2rem;
  gap: 3.6%;
}
@media screen and (max-width: 960px) {
  .company .company-message__content {
    flex-direction: column;
    margin-top: 1rem;
  }
}
.company .company-message__wrapper {
  width: 59%;
}
@media screen and (max-width: 960px) {
  .company .company-message__wrapper {
    width: 100%;
    margin-top: 0.75rem;
  }
}
.company .company-message__image {
  width: 37.5%;
}
@media screen and (max-width: 960px) {
  .company .company-message__image {
    width: 100%;
  }
}
.company .company-message__text {
  font-size: 1.125rem;
  line-height: 1.8;
}
@media screen and (max-width: 960px) {
  .company .company-message__text {
    width: 100%;
    font-size: 1rem;
  }
}
.company .company-message__name {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  text-align: end;
}
.company .company-message__name span {
  margin-right: 0.25rem;
  font-size: 1rem;
}
.company .vision {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #edf0ef;
  background-image: url("../images/company/company_background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 960px) {
  .company .vision {
    padding-top: 3.5rem;
    padding-right: 5%;
    padding-bottom: 3.5rem;
    padding-left: 5%;
  }
}
.company .vision.container {
  max-width: unset;
}
.company .vision .content {
  position: relative;
  width: 50rem;
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .company .vision .content {
    width: 100%;
  }
}
.company .vision h2 {
  color: #db0400;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .vision h2 {
    font-size: 2rem;
  }
}
.company .vision h3 {
  font-size: 2rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .vision h3 {
    font-size: 1.5rem;
  }
}
.company .vision__philosophy {
  margin-top: 3.5rem;
}
@media screen and (max-width: 960px) {
  .company .vision__philosophy {
    margin-top: 2.5rem;
  }
}
.company .vision__subject {
  margin-top: 0.5rem;
  color: #db0400;
  font-size: 1.125rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .vision__subject {
    margin-top: 0.25rem;
    font-size: 0.875rem;
  }
}
.company .vision__list {
  margin-top: 2rem;
}
@media screen and (max-width: 960px) {
  .company .vision__list {
    margin-top: 2.5rem;
  }
}
.company .vision__item {
  display: flex;
  gap: 1%;
}
@media screen and (max-width: 960px) {
  .company .vision__item {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem 1rem;
    border-top: solid 1px #b1b1b1;
  }
}
@media screen and (max-width: 960px) {
  .company .vision__item:last-child {
    border-bottom: solid 1px #b1b1b1;
  }
}
.company .vision__item:last-child p {
  border-bottom: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .company .vision__item:last-child p {
    border: unset;
  }
}
.company .vision__item p {
  padding: 1rem;
  border-top: solid 1px #b1b1b1;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .company .vision__item p {
    padding: unset;
    border: unset;
  }
}
.company .vision__item p:first-child {
  display: flex;
  align-items: center;
  width: 30%;
}
@media screen and (max-width: 960px) {
  .company .vision__item p:first-child {
    width: 100%;
    font-weight: bold;
  }
}
.company .vision__item p:last-child {
  width: 69%;
}
@media screen and (max-width: 960px) {
  .company .vision__item p:last-child {
    width: 100%;
  }
}
.company .vision__text {
  margin-top: 2rem;
  font-family: "Shippori Mincho", serif;
  font-size: 1.5rem;
  line-height: 2;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .vision__text {
    font-size: 1rem;
  }
}
.company .vision__action {
  margin-top: 3.5rem;
  font-family: "Shippori Mincho", serif;
}
@media screen and (max-width: 960px) {
  .company .vision__action {
    margin-top: 2.5rem;
  }
}
.company .vision__action table {
  width: 100%;
  margin-top: 2rem;
}
@media screen and (max-width: 960px) {
  .company .vision__action table {
    margin-top: 1.5rem;
    border-top: solid 1px #b1b1b1;
    border-bottom: solid 1px #b1b1b1;
  }
}
.company .vision__action table tr {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
@media screen and (max-width: 960px) {
  .company .vision__action table tr {
    flex-direction: column;
    align-items: unset;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.company .vision__action table tr:last-of-type td {
  border-bottom: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .company .vision__action table tr:last-of-type td {
    border-bottom: unset;
  }
}
@media screen and (max-width: 960px) {
  .company .vision__action table tr + tr {
    border-top: solid 1px #b1b1b1;
  }
}
.company .vision__action table td {
  display: flex;
  align-items: center;
  height: 4.75rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-top: solid 1px #b1b1b1;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .company .vision__action table td {
    height: auto;
    border-top: unset;
  }
}
.company .vision__action table td:first-of-type {
  width: 30%;
}
@media screen and (max-width: 960px) {
  .company .vision__action table td:first-of-type {
    width: 100%;
    font-weight: 700;
  }
}
.company .vision__action table td:last-of-type {
  width: 70%;
}
@media screen and (max-width: 960px) {
  .company .vision__action table td:last-of-type {
    width: 100%;
  }
}
.company .overview {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .company .overview {
    margin-top: 3.5rem;
  }
}
.company .overview a {
  color: #000;
}
.company .overview .content {
  width: 50rem;
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .company .overview .content {
    width: 100%;
  }
}
.company .overview h2 {
  color: #db0400;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .overview h2 {
    font-size: 2rem;
  }
}
.company .overview table {
  width: 100%;
  margin-top: 2.5rem;
  border-collapse: collapse;
  border: solid 1px #b1b1b1;
}
@media screen and (max-width: 960px) {
  .company .overview table {
    margin-top: 2rem;
  }
}
.company .overview table tr {
  border: solid 1px #b1b1b1;
}
.company .overview table td {
  padding: 1rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .company .overview table td {
    padding: 0.75rem 0.5rem;
    font-size: 0.875rem;
  }
}
.company .overview table td:first-of-type {
  width: 30%;
  background-color: #fff3ef;
}
.company .overview table td:last-of-type {
  width: 70%;
}
.company .overview table .entry-number li {
  line-height: 1.6;
}
@media screen and (max-width: 960px) {
  .company .overview table .entry-number li {
    font-size: 0.75rem;
  }
}
.company .overview table .project li {
  position: relative;
  margin-top: 0.5rem;
  padding-left: 1em;
}
@media screen and (max-width: 960px) {
  .company .overview table .project li {
    font-size: 0.75rem;
    line-height: 1.2;
  }
}
.company .overview table .project li::after {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 0.25rem;
  height: 0.25rem;
  transform: translate(-50%, 50%);
  border-radius: 100vh;
  background-color: #000;
}
@media screen and (max-width: 960px) {
  .company .overview table .project li::after {
    width: 0.188rem;
    height: 0.188rem;
    transform: translate(-50%, 0);
  }
}
.company .overview table .project li:first-of-type {
  margin-top: 0;
}
.company .overview table .license li {
  margin-top: 0.5rem;
}
@media screen and (max-width: 960px) {
  .company .overview table .license li {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 1.2;
  }
}
.company .overview table .license li:first-of-type {
  margin-top: 0;
}
.company .overview__map {
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .company .overview__map {
    margin-top: 0.5rem;
  }
}
.company .overview__map iframe {
  width: 20rem;
  height: 12.5rem;
}
@media screen and (max-width: 960px) {
  .company .overview__map iframe {
    width: 100%;
    height: 7.688rem;
  }
}
.company .certification,
.company .contribution {
  margin-top: 5rem;
}
@media screen and (max-width: 960px) {
  .company .certification,
.company .contribution {
    margin-top: 3.5rem;
  }
}
.company .certification .content,
.company .contribution .content {
  width: 50rem;
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .company .certification .content,
.company .contribution .content {
    width: 100%;
  }
}
.company .certification h2,
.company .contribution h2 {
  color: #db0400;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .certification h2,
.company .contribution h2 {
    font-size: 2rem;
  }
}
.company .certification__collection,
.company .contribution__collection {
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .company .certification__collection,
.company .contribution__collection {
    margin-top: 2rem;
  }
}
.company .certification h3,
.company .contribution h3 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  border-left: solid 0.25rem #db0400;
  font-size: 1.125rem;
  line-height: 1.42;
}
@media screen and (max-width: 960px) {
  .company .certification h3,
.company .contribution h3 {
    font-size: 1rem;
    line-height: 1.4;
  }
}
.company .certification__description,
.company .contribution__description {
  margin-top: 1rem;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .company .certification__description,
.company .contribution__description {
    font-size: 0.875rem;
  }
}
.company .certification p.table-heading,
.company .contribution p.table-heading {
  margin-top: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .company .certification p.table-heading,
.company .contribution p.table-heading {
    margin-top: 1.5rem;
    font-size: 0.875rem;
  }
}
.company .certification table,
.company .contribution table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  border: solid 1px #b1b1b1;
}
.company .certification table tr,
.company .contribution table tr {
  border: solid 1px #b1b1b1;
}
.company .certification table td,
.company .contribution table td {
  padding: 1rem;
  line-height: 1.4;
  vertical-align: top;
}
@media screen and (max-width: 960px) {
  .company .certification table td,
.company .contribution table td {
    padding: 0.75rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.2;
  }
}
.company .certification table td:first-of-type,
.company .contribution table td:first-of-type {
  width: 30%;
  background-color: #fff3ef;
}
.company .certification table td:last-of-type,
.company .contribution table td:last-of-type {
  width: 70%;
}
@media screen and (max-width: 960px) {
  .company .certification table td:last-of-type,
.company .contribution table td:last-of-type {
    vertical-align: middle;
  }
}
.company .certification__logo,
.company .contribution__logo {
  height: 5rem;
}
@media screen and (max-width: 960px) {
  .company .certification__logo,
.company .contribution__logo {
    height: 3.5rem;
  }
}
.company .certification__logo img,
.company .contribution__logo img {
  width: auto;
  height: 100%;
}
.company .certification__text,
.company .contribution__text {
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  .company .certification__text,
.company .contribution__text {
    line-height: 1.4;
  }
}
.company .history {
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  background-color: #edf0ef;
}
@media screen and (max-width: 960px) {
  .company .history {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    padding-top: 3.5rem;
    padding-right: 5%;
    padding-bottom: 3.5rem;
    padding-left: 5%;
  }
}
.company .history.container {
  max-width: unset;
}
.company .history .content {
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
}
.company .history h2 {
  color: #db0400;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .company .history h2 {
    font-size: 2rem;
  }
}
.company .history__group {
  margin-top: 3.5rem;
  border-top: solid 1px #db0400;
  border-bottom: solid 1px #db0400;
}
@media screen and (max-width: 960px) {
  .company .history__group {
    margin-top: 2.5rem;
  }
}
.company .history__item {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-image: linear-gradient(to right, #db0400 0.5rem, transparent 0.5rem);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 1rem 1px;
}
@media screen and (max-width: 960px) {
  .company .history__item {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-image: linear-gradient(to right, #db0400 0.313rem, transparent 0.313rem);
    background-size: 0.625rem 1px;
  }
}
.company .history__item:last-of-type {
  background: unset;
}
.company .history__year {
  color: #db0400;
  font-size: 2.5rem;
}
@media screen and (max-width: 960px) {
  .company .history__year {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}
.company .history__detail {
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
}
@media screen and (max-width: 960px) {
  .company .history__detail {
    flex-direction: column;
  }
}
.company .history__detail p:first-of-type {
  width: 5.625rem;
  color: #db0400;
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .company .history__detail p:first-of-type {
    font-size: 1rem;
    line-height: 1.4;
  }
}
.company .history__detail p:last-of-type {
  font-size: 1.125rem;
}
@media screen and (max-width: 960px) {
  .company .history__detail p:last-of-type {
    font-size: 0.875rem;
    line-height: 1.6;
  }
}