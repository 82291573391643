@use "../abstracts/base" as *;

.common-company {
  background-color: $white;
  
  &.container {
    padding: 3.5rem 5rem 2.5rem;

    @include responsive(in-md) {
      padding: 1.5rem 5% 2.5rem;
    }
  }

  .decoration-text {
    color: $white-smoke;
    font-family: $font-shippori-mincho;
    font-size: 8.75rem;

    @include responsive(in-md) {
      margin-left: -5vw;
      font-size: 4.5rem;
    }
  }

  h2 {
    position: relative;
    z-index: 2;
    margin-top: -1.625rem;
    font-size: 1.625rem;
    font-weight: 700;

    @include responsive(in-md) {
      margin-top: -1.25rem;
      font-size: 1.25rem;
    }
  }

  .detail-text {
    margin-top: 2.5rem;

    @include responsive(in-md) {
      margin-top: 1.5rem;
      line-height: 1.6;
    }
  }

  &__content-link {
    display: flex;
    align-items: center;
    gap: 0 1rem;
    width: fit-content;
    margin-top: 5rem;
    margin-left: auto;

    @include responsive(in-md) {
      gap: 0 0.5rem;
      margin-top: 1rem;
    }

    &:hover {
      p::after {
        width: 100%;
        animation-name: link-hover;
      }
    }

    p {
      position: relative;
      color: $black;

      @include responsive(in-md) {
        font-size: 0.875rem;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        animation-duration: 300ms;
        animation-timing-function: ease-in-out;
        background-color: $black;
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.75rem;
      border: solid 1px $red;
      border-radius: 100vh;
      background-color: #fff;

      @include responsive(in-md) {
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
      }
    }
  }
}
