@use "../abstracts/base" as *;

.common-works {
  position: relative;

  &__background-image {
    width: 100%;
    max-height: 540px;
    overflow: hidden;

    @include responsive(over-lg) {
      background-image: url("../images/common/common_works-link-image.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      img {
        visibility: hidden;
      }
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 3.5rem 5rem 2.5rem;

    @include responsive(in-md) {
      padding: 1rem 5% 1.5rem;
    }

    &__heading {
      p.decoration-text {
        position: relative;
        z-index: 1;
        color: $white;
        font-family: $font-lora;
        font-size: 8.75rem;

        @include responsive(in-md) {
          font-size: 4.5rem;
          text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
        }
      }

      h2 {
        position: relative;
        z-index: 2;
        margin-top: -1.625rem;
        font-size: 1.625rem;
        font-weight: $bold;

        @include responsive(in-md) {
          margin-top: -1.25rem;
          font-size: 1.25rem;
        }
      }

      p.detail-text {
        margin-top: 2.5rem;

        @include responsive(in-md) {
          margin-top: 1rem;
          font-size: 0.875rem;
          line-height: 1.2;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 0 1rem;
      position: absolute;
      right: 5rem;
      bottom: 2.5rem;
      width: fit-content;
      padding: 0.5rem 1.5rem 0.5rem 2.25rem;
      background-color: $red;

      &:hover {
        p::after {
          animation-name: link-hover;
        }
      }

      @include responsive(in-md) {
        right: 1.125rem;
        bottom: 1.5rem;
        gap: 0 0.5rem;
        padding: 0.25rem 0.75rem 0.25rem 1rem;
      }

      p {
        position: relative;
        color: $white;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $white;
          animation-duration: 300ms;
          animation-timing-function: ease-in-out;
        }

        @include responsive(in-md) {
          font-size: 0.875rem;
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem;
        border-radius: 100vh;
        background-color: $white;

        @include responsive(in-md) {
          width: 2rem;
          height: 2rem;
          padding: 0.5rem;
        }
      }
    }
  }
}
