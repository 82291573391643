@use "../abstracts/base" as *;

.header {
  $header: &;

  @include responsive(in-md) {
    background-color: $white;
  }

  &.container {
    position: relative;
    z-index: 99;
    max-width: unset;
    margin-top: 2.5rem;
    padding: 0 2.5rem;

    @include responsive(in-md) {
      display: flex;
      position: sticky;
      top: 0;
      align-items: center;
      justify-content: center;
      height: 3.5rem;
      margin-top: unset;
      padding: 0;
      border-bottom: solid 1px $red;
    }
  }

  &--top {
    #{$header}__logo {
      pointer-events: none;
    }
  }

  // 現在のページのリンクを赤文字にして下線を付ける
  $pageNameList: "top", "services", "works", "company", "recruit";
  @each $pageName in $pageNameList {
    &--#{$pageName} {
      #{$header}__pc-navigation > ul > .#{$pageName} > a {
        color: $red;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          transform: scaleX(1);
          background-color: $red;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
  }

  &__logo {
    width: 5.625rem;

    @include responsive(in-md) {
      position: absolute;
      top: 50%;
      left: 0.5rem;
      height: 2.375rem;
      transform: translateY(-50%);

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &__logotype {
    display: none;

    @include responsive(in-md) {
      display: block;
      width: auto;
      height: 1.875rem;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &__pc-navigation {
    display: flex;
    gap: 0 2.5rem;
    margin-left: auto;

    @include responsive(in-md) {
      display: none;
    }

    ul.main-navigation {
      display: flex;
      align-items: center;
      gap: 0 2rem;
      height: fit-content;
      padding-top: 0.5rem;

      & > li {
        padding-bottom: 1rem;

        a {
          display: inline-block;
          position: relative;
          width: fit-content;
          padding-bottom: 0.25rem;
          transition: all 300ms;
          color: $black;

          &:hover {
            color: $red;

            &::after {
              transform: scaleX(1);
              transform-origin: left center;
            }
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            transform: scaleX(0);
            transform-origin: right center;
            transition: transform 300ms;
            background-color: $red;
          }
        }
      }

      li.services {
        position: relative;

        &:hover {
          ul {
            opacity: 1;
            pointer-events: all;
          }
        }

        ul {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 50%;
          flex-direction: column;
          width: fit-content;
          padding: 1.5rem;
          transform: translate(-50%, 100%);
          transition: all 300ms;
          border-radius: 0.5rem;
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
          opacity: 0;
          background-color: $white;
          pointer-events: none;
          gap: 1.25rem 0;

          &:hover {
            opacity: 1;
            pointer-events: all;
          }

          a {
            white-space: nowrap;
          }
        }
      }
    }

    .pc-tel-info {
      @include responsive(in-md) {
        display: none;
      }

      .number {
        span {
          display: inline-block;
          margin-right: 0.5rem;
          font-size: 1.125rem;
        }

        a {
          color: $black;
          font-size: 2rem;
          pointer-events: none;
        }
      }

      .business-hour {
        margin-top: 0.5rem;
        text-align: right;
      }
    }
  }

  &__navigation-toggle {
    display: none;

    @include responsive(in-md) {
      display: block;
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &.active {
      .border {
        span:nth-child(1) {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }

        span:nth-child(2) {
          display: none;
        }

        span:nth-child(3) {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }

    .border {
      position: relative;
      width: 2rem;
      height: 1.125rem;

      span {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $red;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
        }

        &:nth-child(3) {
          bottom: 0;
          transform: translateY(-50%);
        }
      }
    }

    p {
      margin-top: 0.5rem;
      color: $red;
      font-size: 0.625rem;
    }
  }

  // SPのナビゲーション
  &__sp-navigation {
    display: none;

    @include responsive(in-md) {
      display: block;
      position: absolute;
      z-index: 100;
      top: 3.5rem;
      left: 0;
      width: 100vw;
      height: calc(100vh - 3.5rem);
      /* stylelint-disable */
      padding: 3.5rem 5% 1.5rem;
      padding: 3.5rem 5% calc((100vh - 100svh) + 1.5rem);
      /* stylelint-enable */
      overflow: scroll;
      transition: all 300ms;
      opacity: 0;
      background-color: $white-smoke;
      pointer-events: none;
      overscroll-behavior: contain;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      gap: 2rem 0;

      li.services {
        ul {
          display: flex;
          flex-direction: column;
          gap: 1.5rem 0;
          margin-top: 1.5rem;
          padding-left: 1.5rem;

          li {
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: block;
              width: 1rem;
              height: 1px;
              margin-right: 0.5rem;
              background-color: $red;
            }
          }

          a {
            font-size: 1rem;
          }
        }
      }

      a {
        color: $black;
        font-size: 1.25rem;
      }
    }

    &__tel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 3rem;
      padding: 1.5rem;
      border: solid 2px $red;
      background-color: $white;

      h2 {
        width: fit-content;
        margin: 0 auto;
        padding: 0.5rem 1rem;
        border-radius: 100vh;
        background-color: $red;
        color: $white;
      }

      .sp-tel-info {
        margin-top: 1.125rem;
        text-align: center;

        .tel-number {
          margin-top: 0.5rem;

          a {
            color: $black;
            font-size: 2.5rem;
          }
        }

        .business-hour {
          margin-top: 0.25rem;
          font-size: 0.875rem;
        }
      }
    }
  }
}
