@use "../abstracts/base" as *;

.common-worry {
  &.container {
    max-width: unset;
    padding: 3.5rem 5rem;
    background-color: $light-red;

    @include responsive(in-md) {
      padding: 2.5rem 5%;
    }
  }

  .content {
    max-width: 1260px;
    margin: 0 auto;
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: $bold;

    @include responsive(in-md) {
      font-size: 1rem;
    }

    span {
      display: inline-block;
      padding: 0 0.25rem;
      color: $red;
      font-size: 2.5rem;

      @include responsive(in-md) {
        font-size: 1.75rem;
      }
    }
  }

  &__main-content {
    margin-top: 2rem;
    padding: 3rem 3rem 3.75rem;
    border: solid 2px $red;
    border-radius: 1rem;
    background-color: $white;

    @include responsive(in-md) {
      margin-top: 1.5rem;
      padding: 1.5rem 1.5rem 3rem;
      border-radius: 0.5rem;
    }

    &__list {
      display: table;
      width: 100%;
      border-collapse: collapse;

      @include responsive(in-md) {
        display: flex;
        flex-direction: column;
        gap: 1rem 0;
      }

      li {
        display: table-cell;
        width: 25%;
        padding: 1rem 0;
        border-right: dashed 2px $red;
        border-left: dashed 2px $red;
        text-align: center;

        @include responsive(in-md) {
          width: 100%;
          padding: unset;
          border-right: unset;
          border-left: unset;

          &:not(:first-of-type) {
            padding-top: 1rem;
            border-top: dashed 2px $red;
          }
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.75rem;
          height: 1.75rem;
          margin: 0 auto;
          border-radius: 100vh;
          background-color: $red;
          color: $white;
          font-size: 0.875rem;

          @include responsive(in-md) {
            width: 1.5rem;
            height: 1.5rem;
            border: solid 1px $red;
            background-color: $white;
            color: $red;
            font-size: 0.75rem;
          }
        }

        p {
          margin-top: 0.75rem;
          font-size: 1.125rem;
          font-weight: $bold;
          line-height: 1.2;

          @include responsive(in-md) {
            margin-top: 0.5rem;
            font-feature-settings: "palt";
          }
        }
      }
    }

    .and-more {
      margin-top: 0.5rem;
      font-size: 1.25rem;
      font-weight: $bold;
      text-align: right;

      @include responsive(in-md) {
        margin-top: 1rem;
        font-size: 1rem;
        text-align: center;
      }
    }
  }

  &__illust {
    width: 47%;
    margin: -5rem auto 0;

    @include responsive(in-md) {
      width: 90%;
      margin: -2rem auto 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 0 1rem;
    width: fit-content;
    margin: 2rem auto 0;
    padding: 0.5rem 1.5rem;
    background-color: $red;

    @include responsive(in-md) {
      margin-top: 1.5rem;
    }

    &:hover {
      p::after {
        animation-name: link-hover;
      }
    }

    p {
      position: relative;
      color: $white;
      font-weight: $medium;

      @include responsive(in-md) {
        font-size: 0.875rem;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        animation-duration: 300ms;
        animation-timing-function: ease-in-out;
        background-color: $white;
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.75rem;
      border-radius: 100vh;
      background-color: $white;

      @include responsive(in-md) {
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
      }
    }
  }
}
