@use "../abstracts/base" as *;

.footer {
  &.container {
    padding: 5rem 5rem 1.5rem;
    background-color: $white;

    @include responsive(in-md) {
      padding: 0 0 0.5rem;
    }
  }

  .content {
    position: relative;
  }

  &__main-wrapper {
    display: flex;
    gap: 8.75rem;

    @include responsive(in-md) {
      flex-direction: column-reverse;
      gap: unset;
    }
  }

  &__logo {
    width: 16.25rem;

    @include responsive(in-md) {
      width: 8.75rem;
      margin: 2.5rem auto 0;
    }
  }

  &__menu {
    &__navigation {
      @include responsive(in-md) {
        padding: 2.5rem 5% 1.5rem;
      }

      ul.list {
        & > li {
          a {
            display: block;
            transition: all 300ms;
            color: $black;
            font-size: 1.25rem;
            font-weight: $bold;

            @include responsive(in-md) {
              font-size: 1rem;
            }

            &:hover {
              opacity: 0.6;
            }
          }

          &:nth-child(2) {
            margin-top: 1rem;

            @include responsive(in-md) {
              margin-top: 1.5rem;
            }

            & > ul {
              display: flex;
              gap: 1.5rem;

              @include responsive(in-md) {
                flex-direction: column;
              }

              & > li {
                @include responsive(in-md) {
                  position: relative;
                  padding-left: 1.25rem;
                }

                &::before {
                  @include responsive(in-md) {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 0.5rem;
                    height: 2px;
                    background-color: $black;
                  }
                }

                a {
                  display: inline-block;
                  color: $black;
                  font-size: 1rem;
                  font-weight: $regular;
                }
              }
            }
          }

          &:nth-child(3) {
            display: flex;
            gap: 1.5rem;
            margin-top: 1.5rem;

            @include responsive(in-md) {
              flex-direction: column;
            }

            & > ul {
              display: flex;
              gap: 1.5rem;

              @include responsive(in-md) {
                flex-direction: column;
              }
            }
          }
        }
      }
    }

    &__access {
      @include responsive(in-md) {
        padding: 1.5rem 5%;
        background-color: $white-smoke;
      }

      .map {
        display: flex;
        align-items: center;
        gap: 0 0.875rem;
        margin-top: 2rem;

        @include responsive(in-md) {
          flex-direction: column;
          align-items: unset;
          margin-top: 0;
        }

        p {
          @include responsive(in-md) {
            line-height: 1.2;
          }
        }

        a {
          display: flex;
          align-items: center;
          width: fit-content;
          padding: 0.5rem 1.25rem;
          transition: all 300ms;
          border-radius: 100vh;
          background-color: $red;
          gap: 0.25rem;

          @include responsive(in-md) {
            margin-top: 0.5rem;
            padding: 0.375rem 0.75rem;
          }

          &:hover {
            opacity: 0.6;
          }

          .icon {
            width: 0.729rem;

            @include responsive(in-md) {
              width: 0.688rem;
            }
          }

          p {
            font-size: 0.875rem;
            font-weight: $bold;

            @include responsive(in-md) {
              font-size: 0.75rem;
            }
          }
        }
      }

      .tel {
        margin-top: 1rem;
        font-size: 1.5rem;

        @include responsive(in-md) {
          font-size: 1rem;
        }

        a {
          color: $black;
          pointer-events: none;

          @include responsive(in-md) {
            pointer-events: all;
          }
        }
      }
    }
  }

  &__copyright {
    margin-top: 6.25rem;
    font-size: 0.875rem;

    @include responsive(in-md) {
      margin-top: 2.5rem;
      font-size: 0.625rem;
      text-align: center;
    }
  }

  .scroll-button {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    transition: all 300ms;
    border: solid 1px $red;
    border-radius: 100vh;

    @include responsive(in-md) {
      right: 5%;
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      opacity: 0.6;
    }

    &__arrow {
      width: 1rem;
    }
  }
}
