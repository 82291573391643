@use "../abstracts/base" as *;

.breadcrumb {
  margin-top: 2.5rem;

  ul {
    display: flex;
    align-items: center;
    gap: 0 1rem;

    @include responsive(in-md) {
      gap: 0 0.5rem;
    }

    & > li {
      @include responsive(in-md) {
        font-size: 0.75rem;
      }

      &:not(:first-of-type) {
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 0.75rem;
          margin-right: 1rem;
          background-color: $black;

          @include responsive(in-md) {
            margin-right: 0.5rem;
          }
        }
      }

      & > a {
        color: $line-gray;
        transition: all 300ms;

        &:hover {
          color: $black;
        }
      }
    }
  }
}
